@import url('https://fonts.googleapis.com/css2?family=Aleo:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Sofia&display=swap&family=Roboto&display=swap');

@font-face {
  font-family: 'Arizonia';
  src: local('AlexBrush'), url(webpack:///mini-css-extract-plugin//_next/static/media/Arizonia-Regular.e4073c40.ttf) format('truetype');
}

.font-arizonia {
  font-family: Arizonia, cursive;
}

.font-poppins {
  font-family: Poppins, cursive;
}

.font-sofia {
  font-family: Sofia, cursive;
}

.animate-breathe {
  animation: breathe 4s linear infinite;
}

@keyframes breathe {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

.animate-coin {
  animation: coin 2s linear infinite;
}

@keyframes coin {
  0% {
    transform: rotateY(0);
  }
  100% {
    transform: rotateY(360deg);
  }
}

.animate-flip {
  animation: flip 2s linear infinite;
}

@keyframes flip {
  0% {
    transform: rotateX(0);
  }
  100% {
    transform: rotateX(360deg);
  }
}

*, ::before, ::after {
      --tw-border-spacing-x: 0;
      --tw-border-spacing-y: 0;
      --tw-translate-x: 0;
      --tw-translate-y: 0;
      --tw-rotate: 0;
      --tw-skew-x: 0;
      --tw-skew-y: 0;
      --tw-scale-x: 1;
      --tw-scale-y: 1;
      --tw-pan-x:  ;
      --tw-pan-y:  ;
      --tw-pinch-zoom:  ;
      --tw-scroll-snap-strictness: proximity;
      --tw-gradient-from-position:  ;
      --tw-gradient-via-position:  ;
      --tw-gradient-to-position:  ;
      --tw-ordinal:  ;
      --tw-slashed-zero:  ;
      --tw-numeric-figure:  ;
      --tw-numeric-spacing:  ;
      --tw-numeric-fraction:  ;
      --tw-ring-inset:  ;
      --tw-ring-offset-width: 0px;
      --tw-ring-offset-color: #fff;
      --tw-ring-color: rgb(59 130 246 / 0.5);
      --tw-ring-offset-shadow: 0 0 #0000;
      --tw-ring-shadow: 0 0 #0000;
      --tw-shadow: 0 0 #0000;
      --tw-shadow-colored: 0 0 #0000;
      --tw-blur:  ;
      --tw-brightness:  ;
      --tw-contrast:  ;
      --tw-grayscale:  ;
      --tw-hue-rotate:  ;
      --tw-invert:  ;
      --tw-saturate:  ;
      --tw-sepia:  ;
      --tw-drop-shadow:  ;
      --tw-backdrop-blur:  ;
      --tw-backdrop-brightness:  ;
      --tw-backdrop-contrast:  ;
      --tw-backdrop-grayscale:  ;
      --tw-backdrop-hue-rotate:  ;
      --tw-backdrop-invert:  ;
      --tw-backdrop-opacity:  ;
      --tw-backdrop-saturate:  ;
      --tw-backdrop-sepia:  ;
}
::backdrop {
      --tw-border-spacing-x: 0;
      --tw-border-spacing-y: 0;
      --tw-translate-x: 0;
      --tw-translate-y: 0;
      --tw-rotate: 0;
      --tw-skew-x: 0;
      --tw-skew-y: 0;
      --tw-scale-x: 1;
      --tw-scale-y: 1;
      --tw-pan-x:  ;
      --tw-pan-y:  ;
      --tw-pinch-zoom:  ;
      --tw-scroll-snap-strictness: proximity;
      --tw-gradient-from-position:  ;
      --tw-gradient-via-position:  ;
      --tw-gradient-to-position:  ;
      --tw-ordinal:  ;
      --tw-slashed-zero:  ;
      --tw-numeric-figure:  ;
      --tw-numeric-spacing:  ;
      --tw-numeric-fraction:  ;
      --tw-ring-inset:  ;
      --tw-ring-offset-width: 0px;
      --tw-ring-offset-color: #fff;
      --tw-ring-color: rgb(59 130 246 / 0.5);
      --tw-ring-offset-shadow: 0 0 #0000;
      --tw-ring-shadow: 0 0 #0000;
      --tw-shadow: 0 0 #0000;
      --tw-shadow-colored: 0 0 #0000;
      --tw-blur:  ;
      --tw-brightness:  ;
      --tw-contrast:  ;
      --tw-grayscale:  ;
      --tw-hue-rotate:  ;
      --tw-invert:  ;
      --tw-saturate:  ;
      --tw-sepia:  ;
      --tw-drop-shadow:  ;
      --tw-backdrop-blur:  ;
      --tw-backdrop-brightness:  ;
      --tw-backdrop-contrast:  ;
      --tw-backdrop-grayscale:  ;
      --tw-backdrop-hue-rotate:  ;
      --tw-backdrop-invert:  ;
      --tw-backdrop-opacity:  ;
      --tw-backdrop-saturate:  ;
      --tw-backdrop-sepia:  ;
}
.container {
      width: 100%;
}
@media (min-width: 640px) {
      .container {
            max-width: 640px;
      }
}
@media (min-width: 768px) {
      .container {
            max-width: 768px;
      }
}
@media (min-width: 1024px) {
      .container {
            max-width: 1024px;
      }
}
@media (min-width: 1280px) {
      .container {
            max-width: 1280px;
      }
}
@media (min-width: 1536px) {
      .container {
            max-width: 1536px;
      }
}
.sr-only {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border-width: 0;
}
.pointer-events-none {
      pointer-events: none;
}
.pointer-events-auto {
      pointer-events: auto;
}
.visible {
      visibility: visible;
}
.invisible {
      visibility: hidden;
}
.collapse {
      visibility: collapse;
}
.static {
      position: static;
}
.fixed {
      position: fixed;
}
.absolute {
      position: absolute;
}
.relative {
      position: relative;
}
.sticky {
      position: sticky;
}
.inset-0 {
      inset: 0px;
}
.inset-4 {
      inset: 1rem;
}
.\!left-\[-120px\] {
      left: -120px !important;
}
.-right-0 {
      right: -0px;
}
.-right-0\.5 {
      right: -0.125rem;
}
.-top-0 {
      top: -0px;
}
.-top-0\.5 {
      top: -0.125rem;
}
.-top-\[40px\] {
      top: -40px;
}
.bottom-0 {
      bottom: 0px;
}
.bottom-0\.5 {
      bottom: 0.125rem;
}
.bottom-1 {
      bottom: 0.25rem;
}
.bottom-10 {
      bottom: 2.5rem;
}
.bottom-5 {
      bottom: 1.25rem;
}
.bottom-\[-0\.75rem\] {
      bottom: -0.75rem;
}
.bottom-\[0rem\] {
      bottom: 0rem;
}
.bottom-\[9\.4rem\] {
      bottom: 9.4rem;
}
.left-0 {
      left: 0px;
}
.left-1 {
      left: 0.25rem;
}
.left-1\/2 {
      left: 50%;
}
.left-5 {
      left: 1.25rem;
}
.left-6 {
      left: 1.5rem;
}
.right-0 {
      right: 0px;
}
.right-1 {
      right: 0.25rem;
}
.right-2 {
      right: 0.5rem;
}
.right-3 {
      right: 0.75rem;
}
.right-5 {
      right: 1.25rem;
}
.right-6 {
      right: 1.5rem;
}
.right-\[-10px\] {
      right: -10px;
}
.right-\[-12px\] {
      right: -12px;
}
.right-\[-1rem\] {
      right: -1rem;
}
.right-\[1rem\] {
      right: 1rem;
}
.right-\[76px\] {
      right: 76px;
}
.top-0 {
      top: 0px;
}
.top-0\.5 {
      top: 0.125rem;
}
.top-1 {
      top: 0.25rem;
}
.top-1\.5 {
      top: 0.375rem;
}
.top-1\/2 {
      top: 50%;
}
.top-10 {
      top: 2.5rem;
}
.top-11 {
      top: 2.75rem;
}
.top-14 {
      top: 3.5rem;
}
.top-2 {
      top: 0.5rem;
}
.top-\[-12px\] {
      top: -12px;
}
.top-\[-3px\] {
      top: -3px;
}
.top-\[-8px\] {
      top: -8px;
}
.top-\[0\] {
      top: 0;
}
.top-\[160px\] {
      top: 160px;
}
.top-\[1rem\] {
      top: 1rem;
}
.top-\[2\.875rem\] {
      top: 2.875rem;
}
.-z-10 {
      z-index: -10;
}
.z-0 {
      z-index: 0;
}
.z-10 {
      z-index: 10;
}
.z-20 {
      z-index: 20;
}
.z-30 {
      z-index: 30;
}
.z-40 {
      z-index: 40;
}
.z-50 {
      z-index: 50;
}
.z-\[10000\] {
      z-index: 10000;
}
.z-\[11\] {
      z-index: 11;
}
.order-1 {
      order: 1;
}
.order-2 {
      order: 2;
}
.\!m-0 {
      margin: 0px !important;
}
.\!m-\[-8px\] {
      margin: -8px !important;
}
.m-1 {
      margin: 0.25rem;
}
.m-3 {
      margin: 0.75rem;
}
.m-4 {
      margin: 1rem;
}
.m-6 {
      margin: 1.5rem;
}
.m-auto {
      margin: auto;
}
.\!my-2 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important;
}
.-my-1 {
      margin-top: -0.25rem;
      margin-bottom: -0.25rem;
}
.-my-2 {
      margin-top: -0.5rem;
      margin-bottom: -0.5rem;
}
.-my-2\.5 {
      margin-top: -0.625rem;
      margin-bottom: -0.625rem;
}
.mx-1 {
      margin-left: 0.25rem;
      margin-right: 0.25rem;
}
.mx-3 {
      margin-left: 0.75rem;
      margin-right: 0.75rem;
}
.mx-4 {
      margin-left: 1rem;
      margin-right: 1rem;
}
.mx-5 {
      margin-left: 1.25rem;
      margin-right: 1.25rem;
}
.mx-6 {
      margin-left: 1.5rem;
      margin-right: 1.5rem;
}
.mx-8 {
      margin-left: 2rem;
      margin-right: 2rem;
}
.mx-\[1px\] {
      margin-left: 1px;
      margin-right: 1px;
}
.mx-auto {
      margin-left: auto;
      margin-right: auto;
}
.my-0 {
      margin-top: 0px;
      margin-bottom: 0px;
}
.my-0\.5 {
      margin-top: 0.125rem;
      margin-bottom: 0.125rem;
}
.my-1 {
      margin-top: 0.25rem;
      margin-bottom: 0.25rem;
}
.my-2 {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
}
.my-4 {
      margin-top: 1rem;
      margin-bottom: 1rem;
}
.my-6 {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
}
.my-8 {
      margin-top: 2rem;
      margin-bottom: 2rem;
}
.my-auto {
      margin-top: auto;
      margin-bottom: auto;
}
.my-px {
      margin-top: 1px;
      margin-bottom: 1px;
}
.\!mb-2 {
      margin-bottom: 0.5rem !important;
}
.\!mb-3 {
      margin-bottom: 0.75rem !important;
}
.\!mb-4 {
      margin-bottom: 1rem !important;
}
.\!mt-0 {
      margin-top: 0px !important;
}
.\!mt-1 {
      margin-top: 0.25rem !important;
}
.\!mt-2 {
      margin-top: 0.5rem !important;
}
.\!mt-7 {
      margin-top: 1.75rem !important;
}
.\!mt-\[-12px\] {
      margin-top: -12px !important;
}
.\!mt-\[-8px\] {
      margin-top: -8px !important;
}
.\!mt-\[2px\] {
      margin-top: 2px !important;
}
.\!mt-\[4px\] {
      margin-top: 4px !important;
}
.-mb-1 {
      margin-bottom: -0.25rem;
}
.-mb-2 {
      margin-bottom: -0.5rem;
}
.-mb-4 {
      margin-bottom: -1rem;
}
.-mb-8 {
      margin-bottom: -2rem;
}
.-me-1 {
      margin-inline-end: -0.25rem;
}
.-me-8 {
      margin-inline-end: -2rem;
}
.-ml-2 {
      margin-left: -0.5rem;
}
.-mr-2 {
      margin-right: -0.5rem;
}
.-ms-1 {
      margin-inline-start: -0.25rem;
}
.-mt-2 {
      margin-top: -0.5rem;
}
.-mt-3 {
      margin-top: -0.75rem;
}
.-mt-4 {
      margin-top: -1rem;
}
.-mt-\[5px\] {
      margin-top: -5px;
}
.mb-1 {
      margin-bottom: 0.25rem;
}
.mb-10 {
      margin-bottom: 2.5rem;
}
.mb-14 {
      margin-bottom: 3.5rem;
}
.mb-2 {
      margin-bottom: 0.5rem;
}
.mb-3 {
      margin-bottom: 0.75rem;
}
.mb-4 {
      margin-bottom: 1rem;
}
.mb-5 {
      margin-bottom: 1.25rem;
}
.mb-6 {
      margin-bottom: 1.5rem;
}
.mb-7 {
      margin-bottom: 1.75rem;
}
.mb-8 {
      margin-bottom: 2rem;
}
.mb-80 {
      margin-bottom: 20rem;
}
.mb-\[0\.125rem\] {
      margin-bottom: 0.125rem;
}
.mb-\[0\.5rem\] {
      margin-bottom: 0.5rem;
}
.mb-\[2px\] {
      margin-bottom: 2px;
}
.mb-\[30px\] {
      margin-bottom: 30px;
}
.me-1 {
      margin-inline-end: 0.25rem;
}
.me-2 {
      margin-inline-end: 0.5rem;
}
.me-3 {
      margin-inline-end: 0.75rem;
}
.me-4 {
      margin-inline-end: 1rem;
}
.me-\[-6px\] {
      margin-inline-end: -6px;
}
.me-auto {
      margin-inline-end: auto;
}
.ml-1 {
      margin-left: 0.25rem;
}
.ml-2 {
      margin-left: 0.5rem;
}
.ml-3 {
      margin-left: 0.75rem;
}
.ml-4 {
      margin-left: 1rem;
}
.ml-6 {
      margin-left: 1.5rem;
}
.ml-7 {
      margin-left: 1.75rem;
}
.ml-8 {
      margin-left: 2rem;
}
.ml-\[0\.678rem\] {
      margin-left: 0.678rem;
}
.ml-auto {
      margin-left: auto;
}
.mr-1 {
      margin-right: 0.25rem;
}
.mr-1\.5 {
      margin-right: 0.375rem;
}
.mr-2 {
      margin-right: 0.5rem;
}
.mr-3 {
      margin-right: 0.75rem;
}
.mr-4 {
      margin-right: 1rem;
}
.mr-5 {
      margin-right: 1.25rem;
}
.mr-6 {
      margin-right: 1.5rem;
}
.mr-\[-8px\] {
      margin-right: -8px;
}
.mr-\[50\%\] {
      margin-right: 50%;
}
.mr-auto {
      margin-right: auto;
}
.ms-1 {
      margin-inline-start: 0.25rem;
}
.ms-2 {
      margin-inline-start: 0.5rem;
}
.ms-3 {
      margin-inline-start: 0.75rem;
}
.ms-4 {
      margin-inline-start: 1rem;
}
.ms-\[-6px\] {
      margin-inline-start: -6px;
}
.ms-\[5px\] {
      margin-inline-start: 5px;
}
.ms-auto {
      margin-inline-start: auto;
}
.mt-0 {
      margin-top: 0px;
}
.mt-0\.5 {
      margin-top: 0.125rem;
}
.mt-1 {
      margin-top: 0.25rem;
}
.mt-1\.5 {
      margin-top: 0.375rem;
}
.mt-10 {
      margin-top: 2.5rem;
}
.mt-14 {
      margin-top: 3.5rem;
}
.mt-2 {
      margin-top: 0.5rem;
}
.mt-2\.5 {
      margin-top: 0.625rem;
}
.mt-20 {
      margin-top: 5rem;
}
.mt-24 {
      margin-top: 6rem;
}
.mt-3 {
      margin-top: 0.75rem;
}
.mt-4 {
      margin-top: 1rem;
}
.mt-5 {
      margin-top: 1.25rem;
}
.mt-6 {
      margin-top: 1.5rem;
}
.mt-7 {
      margin-top: 1.75rem;
}
.mt-8 {
      margin-top: 2rem;
}
.mt-9 {
      margin-top: 2.25rem;
}
.mt-\[-0\.125rem\] {
      margin-top: -0.125rem;
}
.mt-\[-10px\] {
      margin-top: -10px;
}
.mt-\[-12px\] {
      margin-top: -12px;
}
.mt-\[-1rem\] {
      margin-top: -1rem;
}
.mt-\[-8px\] {
      margin-top: -8px;
}
.mt-\[0\.125rem\] {
      margin-top: 0.125rem;
}
.mt-\[0\.72rem\] {
      margin-top: 0.72rem;
}
.mt-\[2\.5rem\] {
      margin-top: 2.5rem;
}
.mt-\[30px\] {
      margin-top: 30px;
}
.mt-\[5\.47rem\] {
      margin-top: 5.47rem;
}
.mt-auto {
      margin-top: auto;
}
.block {
      display: block;
}
.inline-block {
      display: inline-block;
}
.inline {
      display: inline;
}
.\!flex {
      display: flex !important;
}
.flex {
      display: flex;
}
.\!inline-flex {
      display: inline-flex !important;
}
.inline-flex {
      display: inline-flex;
}
.table {
      display: table;
}
.grid {
      display: grid;
}
.contents {
      display: contents;
}
.hidden {
      display: none;
}
.aspect-video {
      aspect-ratio: 16 / 9;
}
.\!h-0 {
      height: 0px !important;
}
.\!h-1 {
      height: 0.25rem !important;
}
.\!h-1\.5 {
      height: 0.375rem !important;
}
.\!h-10 {
      height: 2.5rem !important;
}
.\!h-3 {
      height: 0.75rem !important;
}
.\!h-4 {
      height: 1rem !important;
}
.\!h-5 {
      height: 1.25rem !important;
}
.\!h-6 {
      height: 1.5rem !important;
}
.\!h-8 {
      height: 2rem !important;
}
.\!h-\[0\.875\] {
      height: 0.875 !important;
}
.\!h-\[1\.125rem\] {
      height: 1.125rem !important;
}
.\!h-\[1\.2rem\] {
      height: 1.2rem !important;
}
.\!h-\[1\.375rem\] {
      height: 1.375rem !important;
}
.\!h-\[12px\] {
      height: 12px !important;
}
.\!h-\[14px\] {
      height: 14px !important;
}
.\!h-\[15rem\] {
      height: 15rem !important;
}
.\!h-\[1rem\] {
      height: 1rem !important;
}
.\!h-\[2\.375rem\] {
      height: 2.375rem !important;
}
.\!h-\[20px\] {
      height: 20px !important;
}
.\!h-\[2rem\] {
      height: 2rem !important;
}
.\!h-\[500px\] {
      height: 500px !important;
}
.\!h-\[60\%\] {
      height: 60% !important;
}
.\!h-\[65\%\] {
      height: 65% !important;
}
.\!h-\[70vh\] {
      height: 70vh !important;
}
.\!h-\[75\%\] {
      height: 75% !important;
}
.\!h-\[8px\] {
      height: 8px !important;
}
.\!h-\[unset\] {
      height: unset !important;
}
.\!h-auto {
      height: auto !important;
}
.h-0 {
      height: 0px;
}
.h-1 {
      height: 0.25rem;
}
.h-1\.5 {
      height: 0.375rem;
}
.h-10 {
      height: 2.5rem;
}
.h-11 {
      height: 2.75rem;
}
.h-12 {
      height: 3rem;
}
.h-16 {
      height: 4rem;
}
.h-2 {
      height: 0.5rem;
}
.h-2\.5 {
      height: 0.625rem;
}
.h-20 {
      height: 5rem;
}
.h-28 {
      height: 7rem;
}
.h-3 {
      height: 0.75rem;
}
.h-3\.5 {
      height: 0.875rem;
}
.h-32 {
      height: 8rem;
}
.h-4 {
      height: 1rem;
}
.h-40 {
      height: 10rem;
}
.h-5 {
      height: 1.25rem;
}
.h-52 {
      height: 13rem;
}
.h-6 {
      height: 1.5rem;
}
.h-7 {
      height: 1.75rem;
}
.h-8 {
      height: 2rem;
}
.h-9 {
      height: 2.25rem;
}
.h-96 {
      height: 24rem;
}
.h-\[0\.3125rem\] {
      height: 0.3125rem;
}
.h-\[0\.3175rem\] {
      height: 0.3175rem;
}
.h-\[0\.625rem\] {
      height: 0.625rem;
}
.h-\[0\.75rem\] {
      height: 0.75rem;
}
.h-\[0\.9375rem\] {
      height: 0.9375rem;
}
.h-\[0\.984rem\] {
      height: 0.984rem;
}
.h-\[1\.125rem\] {
      height: 1.125rem;
}
.h-\[1\.2rem\] {
      height: 1.2rem;
}
.h-\[1\.375rem\] {
      height: 1.375rem;
}
.h-\[1\.5rem\] {
      height: 1.5rem;
}
.h-\[1\.75rem\] {
      height: 1.75rem;
}
.h-\[1\.875rem\] {
      height: 1.875rem;
}
.h-\[108px\] {
      height: 108px;
}
.h-\[10px\] {
      height: 10px;
}
.h-\[10rem\] {
      height: 10rem;
}
.h-\[12px\] {
      height: 12px;
}
.h-\[12rem\] {
      height: 12rem;
}
.h-\[12vh\] {
      height: 12vh;
}
.h-\[13\.75rem\] {
      height: 13.75rem;
}
.h-\[14px\] {
      height: 14px;
}
.h-\[14rem\] {
      height: 14rem;
}
.h-\[150px\] {
      height: 150px;
}
.h-\[155px\] {
      height: 155px;
}
.h-\[16\.45rem\] {
      height: 16.45rem;
}
.h-\[16px\] {
      height: 16px;
}
.h-\[16rem\] {
      height: 16rem;
}
.h-\[170px\] {
      height: 170px;
}
.h-\[17px\] {
      height: 17px;
}
.h-\[18px\] {
      height: 18px;
}
.h-\[18rem\] {
      height: 18rem;
}
.h-\[19rem\] {
      height: 19rem;
}
.h-\[1px\] {
      height: 1px;
}
.h-\[2\.125rem\] {
      height: 2.125rem;
}
.h-\[2\.25rem\] {
      height: 2.25rem;
}
.h-\[2\.375rem\] {
      height: 2.375rem;
}
.h-\[2\.5px\] {
      height: 2.5px;
}
.h-\[2\.5rem\] {
      height: 2.5rem;
}
.h-\[2\.625rem\] {
      height: 2.625rem;
}
.h-\[2\.9906rem\] {
      height: 2.9906rem;
}
.h-\[200px\] {
      height: 200px;
}
.h-\[20px\] {
      height: 20px;
}
.h-\[20rem\] {
      height: 20rem;
}
.h-\[20vh\] {
      height: 20vh;
}
.h-\[21\.625rem\] {
      height: 21.625rem;
}
.h-\[22\.688rem\] {
      height: 22.688rem;
}
.h-\[22px\] {
      height: 22px;
}
.h-\[23\.5px\] {
      height: 23.5px;
}
.h-\[23rem\] {
      height: 23rem;
}
.h-\[24rem\] {
      height: 24rem;
}
.h-\[25px\] {
      height: 25px;
}
.h-\[25vh\] {
      height: 25vh;
}
.h-\[26\.5rem\] {
      height: 26.5rem;
}
.h-\[26px\] {
      height: 26px;
}
.h-\[26rem\] {
      height: 26rem;
}
.h-\[27\.765rem\] {
      height: 27.765rem;
}
.h-\[27px\] {
      height: 27px;
}
.h-\[28rem\] {
      height: 28rem;
}
.h-\[2rem\] {
      height: 2rem;
}
.h-\[3\.625rem\] {
      height: 3.625rem;
}
.h-\[300px\] {
      height: 300px;
}
.h-\[30px\] {
      height: 30px;
}
.h-\[30rem\] {
      height: 30rem;
}
.h-\[30vh\] {
      height: 30vh;
}
.h-\[31px\] {
      height: 31px;
}
.h-\[32rem\] {
      height: 32rem;
}
.h-\[33px\] {
      height: 33px;
}
.h-\[36\.25vh\] {
      height: 36.25vh;
}
.h-\[37\.5rem\] {
      height: 37.5rem;
}
.h-\[38px\] {
      height: 38px;
}
.h-\[38rem\] {
      height: 38rem;
}
.h-\[39px\] {
      height: 39px;
}
.h-\[3px\] {
      height: 3px;
}
.h-\[4\.5rem\] {
      height: 4.5rem;
}
.h-\[40px\] {
      height: 40px;
}
.h-\[40vh\] {
      height: 40vh;
}
.h-\[42\.5vh\] {
      height: 42.5vh;
}
.h-\[42px\] {
      height: 42px;
}
.h-\[45vh\] {
      height: 45vh;
}
.h-\[4rem\] {
      height: 4rem;
}
.h-\[50\%\] {
      height: 50%;
}
.h-\[50vh\] {
      height: 50vh;
}
.h-\[52px\] {
      height: 52px;
}
.h-\[52vh\] {
      height: 52vh;
}
.h-\[56px\] {
      height: 56px;
}
.h-\[588px\] {
      height: 588px;
}
.h-\[5rem\] {
      height: 5rem;
}
.h-\[6\.688rem\] {
      height: 6.688rem;
}
.h-\[60px\] {
      height: 60px;
}
.h-\[60vh\] {
      height: 60vh;
}
.h-\[64px\] {
      height: 64px;
}
.h-\[65\%\] {
      height: 65%;
}
.h-\[650px\] {
      height: 650px;
}
.h-\[67px\] {
      height: 67px;
}
.h-\[70vh\] {
      height: 70vh;
}
.h-\[72px\] {
      height: 72px;
}
.h-\[75vh\] {
      height: 75vh;
}
.h-\[76px\] {
      height: 76px;
}
.h-\[7rem\] {
      height: 7rem;
}
.h-\[8\.063rem\] {
      height: 8.063rem;
}
.h-\[800px\] {
      height: 800px;
}
.h-\[80vh\] {
      height: 80vh;
}
.h-\[82px\] {
      height: 82px;
}
.h-\[85\%\] {
      height: 85%;
}
.h-\[8rem\] {
      height: 8rem;
}
.h-\[96vh\] {
      height: 96vh;
}
.h-\[calc\(100\%-56px\)\] {
      height: calc(100% - 56px);
}
.h-\[calc\(100vh-11\.25rem\)\] {
      height: calc(100vh - 11.25rem);
}
.h-\[calc\(100vh-12\.375rem\)\] {
      height: calc(100vh - 12.375rem);
}
.h-\[calc\(100vh-12rem\)\] {
      height: calc(100vh - 12rem);
}
.h-\[calc\(100vh-14rem\)\] {
      height: calc(100vh - 14rem);
}
.h-\[calc\(100vh-16rem\)\] {
      height: calc(100vh - 16rem);
}
.h-\[calc\(100vh-4rem\)\] {
      height: calc(100vh - 4rem);
}
.h-\[calc\(100vh-86px\)\] {
      height: calc(100vh - 86px);
}
.h-\[calc\(100vh-9rem\)\] {
      height: calc(100vh - 9rem);
}
.h-auto {
      height: auto;
}
.h-full {
      height: 100%;
}
.h-max {
      height: -moz-max-content;
      height: max-content;
}
.h-screen {
      height: 100vh;
}
.max-h-0 {
      max-height: 0px;
}
.max-h-16 {
      max-height: 4rem;
}
.max-h-48 {
      max-height: 12rem;
}
.max-h-80 {
      max-height: 20rem;
}
.max-h-9 {
      max-height: 2.25rem;
}
.max-h-96 {
      max-height: 24rem;
}
.max-h-\[0px\] {
      max-height: 0px;
}
.max-h-\[10000px\] {
      max-height: 10000px;
}
.max-h-\[1000px\] {
      max-height: 1000px;
}
.max-h-\[1000rem\] {
      max-height: 1000rem;
}
.max-h-\[100rem\] {
      max-height: 100rem;
}
.max-h-\[100vh\] {
      max-height: 100vh;
}
.max-h-\[200px\] {
      max-height: 200px;
}
.max-h-\[20rem\] {
      max-height: 20rem;
}
.max-h-\[250px\] {
      max-height: 250px;
}
.max-h-\[258px\] {
      max-height: 258px;
}
.max-h-\[280px\] {
      max-height: 280px;
}
.max-h-\[3\.3rem\] {
      max-height: 3.3rem;
}
.max-h-\[330px\] {
      max-height: 330px;
}
.max-h-\[35rem\] {
      max-height: 35rem;
}
.max-h-\[39\.75rem\] {
      max-height: 39.75rem;
}
.max-h-\[400px\] {
      max-height: 400px;
}
.max-h-\[50\%\] {
      max-height: 50%;
}
.max-h-\[50\.5rem\] {
      max-height: 50.5rem;
}
.max-h-\[52\.375rem\] {
      max-height: 52.375rem;
}
.max-h-\[62\.5rem\] {
      max-height: 62.5rem;
}
.max-h-\[6rem\] {
      max-height: 6rem;
}
.max-h-\[70vh\] {
      max-height: 70vh;
}
.max-h-\[73vh\] {
      max-height: 73vh;
}
.max-h-\[75vh\] {
      max-height: 75vh;
}
.max-h-\[90vh\] {
      max-height: 90vh;
}
.max-h-\[95vh\] {
      max-height: 95vh;
}
.max-h-\[calc\(100\%-4rem\)\] {
      max-height: calc(100% - 4rem);
}
.max-h-\[calc\(100vh-4\.25rem\)\] {
      max-height: calc(100vh - 4.25rem);
}
.max-h-full {
      max-height: 100%;
}
.max-h-screen {
      max-height: 100vh;
}
.\!min-h-\[76px\] {
      min-height: 76px !important;
}
.\!min-h-\[calc\(100vh-20rem\)\] {
      min-height: calc(100vh - 20rem) !important;
}
.min-h-0 {
      min-height: 0px;
}
.min-h-\[1\.25rem\] {
      min-height: 1.25rem;
}
.min-h-\[11\.4rem\] {
      min-height: 11.4rem;
}
.min-h-\[15rem\] {
      min-height: 15rem;
}
.min-h-\[19\.4rem\] {
      min-height: 19.4rem;
}
.min-h-\[20px\] {
      min-height: 20px;
}
.min-h-\[20rem\] {
      min-height: 20rem;
}
.min-h-\[250px\] {
      min-height: 250px;
}
.min-h-\[25rem\] {
      min-height: 25rem;
}
.min-h-\[3\.5rem\] {
      min-height: 3.5rem;
}
.min-h-\[30\%\] {
      min-height: 30%;
}
.min-h-\[30rem\] {
      min-height: 30rem;
}
.min-h-\[30vh\] {
      min-height: 30vh;
}
.min-h-\[32px\] {
      min-height: 32px;
}
.min-h-\[376px\] {
      min-height: 376px;
}
.min-h-\[3rem\] {
      min-height: 3rem;
}
.min-h-\[4\.375rem\] {
      min-height: 4.375rem;
}
.min-h-\[45vh\] {
      min-height: 45vh;
}
.min-h-\[50vh\] {
      min-height: 50vh;
}
.min-h-\[5rem\] {
      min-height: 5rem;
}
.min-h-\[6rem\] {
      min-height: 6rem;
}
.min-h-\[82px\] {
      min-height: 82px;
}
.min-h-\[calc\(79vh-3rem\)\] {
      min-height: calc(79vh - 3rem);
}
.min-h-full {
      min-height: 100%;
}
.min-h-screen {
      min-height: 100vh;
}
.\!w-0 {
      width: 0px !important;
}
.\!w-1 {
      width: 0.25rem !important;
}
.\!w-1\.5 {
      width: 0.375rem !important;
}
.\!w-1\/2 {
      width: 50% !important;
}
.\!w-10 {
      width: 2.5rem !important;
}
.\!w-24 {
      width: 6rem !important;
}
.\!w-3 {
      width: 0.75rem !important;
}
.\!w-4 {
      width: 1rem !important;
}
.\!w-5 {
      width: 1.25rem !important;
}
.\!w-6 {
      width: 1.5rem !important;
}
.\!w-\[0\.875rem\] {
      width: 0.875rem !important;
}
.\!w-\[1\.125rem\] {
      width: 1.125rem !important;
}
.\!w-\[1\.2rem\] {
      width: 1.2rem !important;
}
.\!w-\[10\.25rem\] {
      width: 10.25rem !important;
}
.\!w-\[11\.75rem\] {
      width: 11.75rem !important;
}
.\!w-\[1200px\] {
      width: 1200px !important;
}
.\!w-\[12px\] {
      width: 12px !important;
}
.\!w-\[14px\] {
      width: 14px !important;
}
.\!w-\[150px\] {
      width: 150px !important;
}
.\!w-\[16px\] {
      width: 16px !important;
}
.\!w-\[17\.5rem\] {
      width: 17.5rem !important;
}
.\!w-\[18px\] {
      width: 18px !important;
}
.\!w-\[1rem\] {
      width: 1rem !important;
}
.\!w-\[20px\] {
      width: 20px !important;
}
.\!w-\[250px\] {
      width: 250px !important;
}
.\!w-\[2rem\] {
      width: 2rem !important;
}
.\!w-\[44px\] {
      width: 44px !important;
}
.\!w-\[6rem\] {
      width: 6rem !important;
}
.\!w-\[8px\] {
      width: 8px !important;
}
.\!w-full {
      width: 100% !important;
}
.w-0 {
      width: 0px;
}
.w-1 {
      width: 0.25rem;
}
.w-1\.5 {
      width: 0.375rem;
}
.w-1\/2 {
      width: 50%;
}
.w-1\/3 {
      width: 33.333333%;
}
.w-10 {
      width: 2.5rem;
}
.w-12 {
      width: 3rem;
}
.w-16 {
      width: 4rem;
}
.w-2 {
      width: 0.5rem;
}
.w-2\.5 {
      width: 0.625rem;
}
.w-2\/3 {
      width: 66.666667%;
}
.w-24 {
      width: 6rem;
}
.w-28 {
      width: 7rem;
}
.w-3 {
      width: 0.75rem;
}
.w-3\.5 {
      width: 0.875rem;
}
.w-4 {
      width: 1rem;
}
.w-4\/5 {
      width: 80%;
}
.w-48 {
      width: 12rem;
}
.w-5 {
      width: 1.25rem;
}
.w-56 {
      width: 14rem;
}
.w-6 {
      width: 1.5rem;
}
.w-64 {
      width: 16rem;
}
.w-7 {
      width: 1.75rem;
}
.w-8 {
      width: 2rem;
}
.w-9 {
      width: 2.25rem;
}
.w-96 {
      width: 24rem;
}
.w-\[0\.0625rem\] {
      width: 0.0625rem;
}
.w-\[0\.86191rem\] {
      width: 0.86191rem;
}
.w-\[0\.875rem\] {
      width: 0.875rem;
}
.w-\[0\.9375rem\] {
      width: 0.9375rem;
}
.w-\[1\.125\] {
      width: 1.125;
}
.w-\[1\.125rem\] {
      width: 1.125rem;
}
.w-\[1\.2rem\] {
      width: 1.2rem;
}
.w-\[1\.375rem\] {
      width: 1.375rem;
}
.w-\[1\.5rem\] {
      width: 1.5rem;
}
.w-\[1\.75rem\] {
      width: 1.75rem;
}
.w-\[10\%\] {
      width: 10%;
}
.w-\[10\.8125rem\] {
      width: 10.8125rem;
}
.w-\[1000px\] {
      width: 1000px;
}
.w-\[100px\] {
      width: 100px;
}
.w-\[1024px\] {
      width: 1024px;
}
.w-\[10px\] {
      width: 10px;
}
.w-\[10rem\] {
      width: 10rem;
}
.w-\[11\.125rem\] {
      width: 11.125rem;
}
.w-\[11\.5rem\] {
      width: 11.5rem;
}
.w-\[11\.75rem\] {
      width: 11.75rem;
}
.w-\[11rem\] {
      width: 11rem;
}
.w-\[12\.5rem\] {
      width: 12.5rem;
}
.w-\[1200px\] {
      width: 1200px;
}
.w-\[120px\] {
      width: 120px;
}
.w-\[125px\] {
      width: 125px;
}
.w-\[12px\] {
      width: 12px;
}
.w-\[12rem\] {
      width: 12rem;
}
.w-\[13\.75rem\] {
      width: 13.75rem;
}
.w-\[14px\] {
      width: 14px;
}
.w-\[150px\] {
      width: 150px;
}
.w-\[15rem\] {
      width: 15rem;
}
.w-\[16\.4375rem\] {
      width: 16.4375rem;
}
.w-\[166px\] {
      width: 166px;
}
.w-\[16px\] {
      width: 16px;
}
.w-\[18px\] {
      width: 18px;
}
.w-\[18rem\] {
      width: 18rem;
}
.w-\[192px\] {
      width: 192px;
}
.w-\[1px\] {
      width: 1px;
}
.w-\[1rem\] {
      width: 1rem;
}
.w-\[2\.125rem\] {
      width: 2.125rem;
}
.w-\[2\.25rem\] {
      width: 2.25rem;
}
.w-\[2\.5rem\] {
      width: 2.5rem;
}
.w-\[2\.9906rem\] {
      width: 2.9906rem;
}
.w-\[200px\] {
      width: 200px;
}
.w-\[20rem\] {
      width: 20rem;
}
.w-\[214px\] {
      width: 214px;
}
.w-\[21rem\] {
      width: 21rem;
}
.w-\[22px\] {
      width: 22px;
}
.w-\[22rem\] {
      width: 22rem;
}
.w-\[23rem\] {
      width: 23rem;
}
.w-\[25rem\] {
      width: 25rem;
}
.w-\[264px\] {
      width: 264px;
}
.w-\[27\.25vw\] {
      width: 27.25vw;
}
.w-\[28rem\] {
      width: 28rem;
}
.w-\[2px\] {
      width: 2px;
}
.w-\[2rem\] {
      width: 2rem;
}
.w-\[30px\] {
      width: 30px;
}
.w-\[31rem\] {
      width: 31rem;
}
.w-\[33rem\] {
      width: 33rem;
}
.w-\[340px\] {
      width: 340px;
}
.w-\[350px\] {
      width: 350px;
}
.w-\[37\.5rem\] {
      width: 37.5rem;
}
.w-\[37\.875rem\] {
      width: 37.875rem;
}
.w-\[38\.875rem\] {
      width: 38.875rem;
}
.w-\[4\.5rem\] {
      width: 4.5rem;
}
.w-\[40rem\] {
      width: 40rem;
}
.w-\[45\%\] {
      width: 45%;
}
.w-\[45\.25rem\] {
      width: 45.25rem;
}
.w-\[45\.5rem\] {
      width: 45.5rem;
}
.w-\[45px\] {
      width: 45px;
}
.w-\[45rem\] {
      width: 45rem;
}
.w-\[46\.875rem\] {
      width: 46.875rem;
}
.w-\[47rem\] {
      width: 47rem;
}
.w-\[48rem\] {
      width: 48rem;
}
.w-\[4rem\] {
      width: 4rem;
}
.w-\[5\.1875rem\] {
      width: 5.1875rem;
}
.w-\[5\.5rem\] {
      width: 5.5rem;
}
.w-\[50\%\] {
      width: 50%;
}
.w-\[500px\] {
      width: 500px;
}
.w-\[50rem\] {
      width: 50rem;
}
.w-\[51\.5rem\] {
      width: 51.5rem;
}
.w-\[52\.5rem\] {
      width: 52.5rem;
}
.w-\[526px\] {
      width: 526px;
}
.w-\[528px\] {
      width: 528px;
}
.w-\[52px\] {
      width: 52px;
}
.w-\[53\.3125rem\] {
      width: 53.3125rem;
}
.w-\[53rem\] {
      width: 53rem;
}
.w-\[54rem\] {
      width: 54rem;
}
.w-\[55\%\] {
      width: 55%;
}
.w-\[55\.5rem\] {
      width: 55.5rem;
}
.w-\[550px\] {
      width: 550px;
}
.w-\[56\.875rem\] {
      width: 56.875rem;
}
.w-\[6\.625rem\] {
      width: 6.625rem;
}
.w-\[60\%\] {
      width: 60%;
}
.w-\[62\.5rem\] {
      width: 62.5rem;
}
.w-\[620px\] {
      width: 620px;
}
.w-\[624px\] {
      width: 624px;
}
.w-\[64\.875rem\] {
      width: 64.875rem;
}
.w-\[64px\] {
      width: 64px;
}
.w-\[65\.625rem\] {
      width: 65.625rem;
}
.w-\[65rem\] {
      width: 65rem;
}
.w-\[66\.875rem\] {
      width: 66.875rem;
}
.w-\[67rem\] {
      width: 67rem;
}
.w-\[6rem\] {
      width: 6rem;
}
.w-\[7\.5rem\] {
      width: 7.5rem;
}
.w-\[7\.9375rem\] {
      width: 7.9375rem;
}
.w-\[72\.125rem\] {
      width: 72.125rem;
}
.w-\[73\.125rem\] {
      width: 73.125rem;
}
.w-\[73\.25rem\] {
      width: 73.25rem;
}
.w-\[76px\] {
      width: 76px;
}
.w-\[7rem\] {
      width: 7rem;
}
.w-\[8\.34rem\] {
      width: 8.34rem;
}
.w-\[8\.75rem\] {
      width: 8.75rem;
}
.w-\[80\%\] {
      width: 80%;
}
.w-\[80px\] {
      width: 80px;
}
.w-\[888px\] {
      width: 888px;
}
.w-\[8rem\] {
      width: 8rem;
}
.w-\[9\.2rem\] {
      width: 9.2rem;
}
.w-\[90px\] {
      width: 90px;
}
.w-\[90vw\] {
      width: 90vw;
}
.w-\[96px\] {
      width: 96px;
}
.w-\[96rem\] {
      width: 96rem;
}
.w-\[980px\] {
      width: 980px;
}
.w-\[calc\(100\%-10px\)\] {
      width: calc(100% - 10px);
}
.w-\[calc\(100\%-13\.75rem\)\] {
      width: calc(100% - 13.75rem);
}
.w-\[calc\(100\%-4\.5rem\)\] {
      width: calc(100% - 4.5rem);
}
.w-\[calc\(100vw-240px\)\] {
      width: calc(100vw - 240px);
}
.w-\[calc\(100vw-7rem\)\] {
      width: calc(100vw - 7rem);
}
.w-\[calc\(50\%-0\.75rem\)\] {
      width: calc(50% - 0.75rem);
}
.w-auto {
      width: auto;
}
.w-fit {
      width: -moz-fit-content;
      width: fit-content;
}
.w-full {
      width: 100%;
}
.w-max {
      width: -moz-max-content;
      width: max-content;
}
.w-min {
      width: -moz-min-content;
      width: min-content;
}
.w-screen {
      width: 100vw;
}
.min-w-\[1000px\] {
      min-width: 1000px;
}
.min-w-\[1090px\] {
      min-width: 1090px;
}
.min-w-\[12\.5rem\] {
      min-width: 12.5rem;
}
.min-w-\[12rem\] {
      min-width: 12rem;
}
.min-w-\[15rem\] {
      min-width: 15rem;
}
.min-w-\[1rem\] {
      min-width: 1rem;
}
.min-w-\[20rem\] {
      min-width: 20rem;
}
.min-w-\[22\.5rem\] {
      min-width: 22.5rem;
}
.min-w-\[23rem\] {
      min-width: 23rem;
}
.min-w-\[300px\] {
      min-width: 300px;
}
.min-w-\[50\%\] {
      min-width: 50%;
}
.min-w-\[50rem\] {
      min-width: 50rem;
}
.min-w-\[60\%\] {
      min-width: 60%;
}
.min-w-\[8\.75rem\] {
      min-width: 8.75rem;
}
.min-w-\[80px\] {
      min-width: 80px;
}
.min-w-\[96px\] {
      min-width: 96px;
}
.min-w-full {
      min-width: 100%;
}
.min-w-min {
      min-width: -moz-min-content;
      min-width: min-content;
}
.\!max-w-\[calc\(50\%-0\.75rem\)\] {
      max-width: calc(50% - 0.75rem) !important;
}
.\!max-w-full {
      max-width: 100% !important;
}
.max-w-2xl {
      max-width: 42rem;
}
.max-w-3xl {
      max-width: 48rem;
}
.max-w-\[10\%\] {
      max-width: 10%;
}
.max-w-\[10\.5625rem\] {
      max-width: 10.5625rem;
}
.max-w-\[100\%\] {
      max-width: 100%;
}
.max-w-\[1000px\] {
      max-width: 1000px;
}
.max-w-\[100px\] {
      max-width: 100px;
}
.max-w-\[100vw\] {
      max-width: 100vw;
}
.max-w-\[10rem\] {
      max-width: 10rem;
}
.max-w-\[11\.75rem\] {
      max-width: 11.75rem;
}
.max-w-\[12\.0625rem\] {
      max-width: 12.0625rem;
}
.max-w-\[120px\] {
      max-width: 120px;
}
.max-w-\[12rem\] {
      max-width: 12rem;
}
.max-w-\[13\.125rem\] {
      max-width: 13.125rem;
}
.max-w-\[13rem\] {
      max-width: 13rem;
}
.max-w-\[150px\] {
      max-width: 150px;
}
.max-w-\[15rem\] {
      max-width: 15rem;
}
.max-w-\[197px\] {
      max-width: 197px;
}
.max-w-\[20\.72\%\] {
      max-width: 20.72%;
}
.max-w-\[20\.75rem\] {
      max-width: 20.75rem;
}
.max-w-\[200px\] {
      max-width: 200px;
}
.max-w-\[20rem\] {
      max-width: 20rem;
}
.max-w-\[24\%\] {
      max-width: 24%;
}
.max-w-\[246px\] {
      max-width: 246px;
}
.max-w-\[250px\] {
      max-width: 250px;
}
.max-w-\[25rem\] {
      max-width: 25rem;
}
.max-w-\[27\.72\%\] {
      max-width: 27.72%;
}
.max-w-\[296px\] {
      max-width: 296px;
}
.max-w-\[30rem\] {
      max-width: 30rem;
}
.max-w-\[340px\] {
      max-width: 340px;
}
.max-w-\[35rem\] {
      max-width: 35rem;
}
.max-w-\[38\%\] {
      max-width: 38%;
}
.max-w-\[400px\] {
      max-width: 400px;
}
.max-w-\[42\%\] {
      max-width: 42%;
}
.max-w-\[42rem\] {
      max-width: 42rem;
}
.max-w-\[448px\] {
      max-width: 448px;
}
.max-w-\[450px\] {
      max-width: 450px;
}
.max-w-\[470px\] {
      max-width: 470px;
}
.max-w-\[50\%\] {
      max-width: 50%;
}
.max-w-\[52\.5rem\] {
      max-width: 52.5rem;
}
.max-w-\[550px\] {
      max-width: 550px;
}
.max-w-\[610px\] {
      max-width: 610px;
}
.max-w-\[62\.5rem\] {
      max-width: 62.5rem;
}
.max-w-\[66\%\] {
      max-width: 66%;
}
.max-w-\[67rem\] {
      max-width: 67rem;
}
.max-w-\[700px\] {
      max-width: 700px;
}
.max-w-\[75\%\] {
      max-width: 75%;
}
.max-w-\[800px\] {
      max-width: 800px;
}
.max-w-\[85vw\] {
      max-width: 85vw;
}
.max-w-\[8rem\] {
      max-width: 8rem;
}
.max-w-\[90vw\] {
      max-width: 90vw;
}
.max-w-\[95\%\] {
      max-width: 95%;
}
.max-w-\[9rem\] {
      max-width: 9rem;
}
.max-w-\[calc\(100\%-16px\)\] {
      max-width: calc(100% - 16px);
}
.max-w-\[calc\(100\%-2rem\)\] {
      max-width: calc(100% - 2rem);
}
.max-w-\[calc\(100\%-45px\)\] {
      max-width: calc(100% - 45px);
}
.max-w-\[calc\(100\%-50px\)\] {
      max-width: calc(100% - 50px);
}
.max-w-\[calc\(33\.333\%-1rem\)\] {
      max-width: calc(33.333% - 1rem);
}
.max-w-full {
      max-width: 100%;
}
.max-w-sm {
      max-width: 24rem;
}
.max-w-xl {
      max-width: 36rem;
}
.max-w-xs {
      max-width: 20rem;
}
.flex-1 {
      flex: 1 1 0%;
}
.flex-2 {
      flex: 2 2 0%;
}
.flex-\[2\] {
      flex: 2;
}
.flex-shrink-0 {
      flex-shrink: 0;
}
.shrink-0 {
      flex-shrink: 0;
}
.flex-grow {
      flex-grow: 1;
}
.grow {
      flex-grow: 1;
}
.grow-0 {
      flex-grow: 0;
}
.border-collapse {
      border-collapse: collapse;
}
.border-separate {
      border-collapse: separate;
}
.\!border-spacing-y-3 {
      --tw-border-spacing-y: 0.75rem !important;
      border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}
.border-spacing-x-0 {
      --tw-border-spacing-x: 0px;
      border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}
.border-spacing-y-1 {
      --tw-border-spacing-y: 0.25rem;
      border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}
.border-spacing-y-1\.5 {
      --tw-border-spacing-y: 0.375rem;
      border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}
.origin-\[0\] {
      transform-origin: 0;
}
.origin-bottom-right {
      transform-origin: bottom right;
}
.-translate-x-1\/2 {
      --tw-translate-x: -50%;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-translate-y-1\/2 {
      --tw-translate-y: -50%;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-translate-y-8 {
      --tw-translate-y: -2rem;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-x-0 {
      --tw-translate-x: 0px;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-x-2 {
      --tw-translate-x: 0.5rem;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-x-\[-50\%\] {
      --tw-translate-x: -50%;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-x-full {
      --tw-translate-x: 100%;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-0 {
      --tw-translate-y: 0px;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-\[500px\] {
      --tw-translate-y: 500px;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.rotate-180 {
      --tw-rotate: 180deg;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.rotate-90 {
      --tw-rotate: 90deg;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-0 {
      --tw-scale-x: 0;
      --tw-scale-y: 0;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-100 {
      --tw-scale-x: 1;
      --tw-scale-y: 1;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-150 {
      --tw-scale-x: 1.5;
      --tw-scale-y: 1.5;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-75 {
      --tw-scale-x: .75;
      --tw-scale-y: .75;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-95 {
      --tw-scale-x: .95;
      --tw-scale-y: .95;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-\[2\] {
      --tw-scale-x: 2;
      --tw-scale-y: 2;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-y-90 {
      --tw-scale-y: .9;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-y-\[-1\] {
      --tw-scale-y: -1;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.transform {
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
@keyframes spin {
      to {
            transform: rotate(360deg);
      }
}
.animate-\[spin_1s_linear_infinite_reverse\] {
      animation: spin 1s linear infinite reverse;
}
@keyframes pulse {
      50% {
            opacity: .5;
      }
}
.animate-pulse {
      animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
@keyframes spin {
      to {
            transform: rotate(360deg);
      }
}
.animate-spin {
      animation: spin 1s linear infinite;
}
.cursor-default {
      cursor: default;
}
.cursor-help {
      cursor: help;
}
.cursor-move {
      cursor: move;
}
.cursor-not-allowed {
      cursor: not-allowed;
}
.cursor-pointer {
      cursor: pointer;
}
.cursor-text {
      cursor: text;
}
.cursor-wait {
      cursor: wait;
}
.select-all {
      -webkit-user-select: all;
         -moz-user-select: all;
              user-select: all;
}
.resize-none {
      resize: none;
}
.resize {
      resize: both;
}
.list-inside {
      list-style-position: inside;
}
.appearance-none {
      -webkit-appearance: none;
         -moz-appearance: none;
              appearance: none;
}
.grid-cols-1 {
      grid-template-columns: repeat(1, minmax(0, 1fr));
}
.grid-cols-2 {
      grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid-cols-3 {
      grid-template-columns: repeat(3, minmax(0, 1fr));
}
.grid-cols-4 {
      grid-template-columns: repeat(4, minmax(0, 1fr));
}
.grid-rows-4 {
      grid-template-rows: repeat(4, minmax(0, 1fr));
}
.grid-rows-\[1fr_auto_auto\] {
      grid-template-rows: 1fr auto auto;
}
.grid-rows-\[auto_1fr_auto_auto\] {
      grid-template-rows: auto 1fr auto auto;
}
.\!flex-row {
      flex-direction: row !important;
}
.flex-row {
      flex-direction: row;
}
.\!flex-row-reverse {
      flex-direction: row-reverse !important;
}
.flex-row-reverse {
      flex-direction: row-reverse;
}
.flex-col {
      flex-direction: column;
}
.flex-wrap {
      flex-wrap: wrap;
}
.\!flex-wrap-reverse {
      flex-wrap: wrap-reverse !important;
}
.items-start {
      align-items: flex-start;
}
.items-end {
      align-items: flex-end;
}
.\!items-center {
      align-items: center !important;
}
.items-center {
      align-items: center;
}
.items-baseline {
      align-items: baseline;
}
.\!items-stretch {
      align-items: stretch !important;
}
.items-stretch {
      align-items: stretch;
}
.justify-start {
      justify-content: flex-start;
}
.justify-end {
      justify-content: flex-end;
}
.\!justify-center {
      justify-content: center !important;
}
.justify-center {
      justify-content: center;
}
.justify-between {
      justify-content: space-between;
}
.justify-around {
      justify-content: space-around;
}
.justify-items-stretch {
      justify-items: stretch;
}
.\!gap-0 {
      gap: 0px !important;
}
.\!gap-0\.5 {
      gap: 0.125rem !important;
}
.\!gap-1 {
      gap: 0.25rem !important;
}
.\!gap-2 {
      gap: 0.5rem !important;
}
.\!gap-3 {
      gap: 0.75rem !important;
}
.\!gap-4 {
      gap: 1rem !important;
}
.\!gap-6 {
      gap: 1.5rem !important;
}
.gap-0 {
      gap: 0px;
}
.gap-0\.5 {
      gap: 0.125rem;
}
.gap-1 {
      gap: 0.25rem;
}
.gap-1\.5 {
      gap: 0.375rem;
}
.gap-10 {
      gap: 2.5rem;
}
.gap-12 {
      gap: 3rem;
}
.gap-14 {
      gap: 3.5rem;
}
.gap-16 {
      gap: 4rem;
}
.gap-2 {
      gap: 0.5rem;
}
.gap-2\.5 {
      gap: 0.625rem;
}
.gap-24 {
      gap: 6rem;
}
.gap-3 {
      gap: 0.75rem;
}
.gap-3\.5 {
      gap: 0.875rem;
}
.gap-32 {
      gap: 8rem;
}
.gap-4 {
      gap: 1rem;
}
.gap-5 {
      gap: 1.25rem;
}
.gap-6 {
      gap: 1.5rem;
}
.gap-8 {
      gap: 2rem;
}
.gap-9 {
      gap: 2.25rem;
}
.gap-\[0\.1875rem\] {
      gap: 0.1875rem;
}
.gap-\[0\.38rem\] {
      gap: 0.38rem;
}
.gap-\[0\.5rem\] {
      gap: 0.5rem;
}
.gap-\[0\.65rem\] {
      gap: 0.65rem;
}
.gap-\[0\.75rem\] {
      gap: 0.75rem;
}
.gap-\[0\.8rem\] {
      gap: 0.8rem;
}
.gap-\[1\.375rem\] {
      gap: 1.375rem;
}
.gap-\[1\.5rem\] {
      gap: 1.5rem;
}
.gap-\[10rem\] {
      gap: 10rem;
}
.gap-\[1px\] {
      gap: 1px;
}
.gap-\[2\.25rem\] {
      gap: 2.25rem;
}
.gap-\[22px\] {
      gap: 22px;
}
.gap-\[2px\] {
      gap: 2px;
}
.gap-\[4\.875rem\] {
      gap: 4.875rem;
}
.gap-\[52px\] {
      gap: 52px;
}
.gap-x-16 {
      -moz-column-gap: 4rem;
           column-gap: 4rem;
}
.gap-x-4 {
      -moz-column-gap: 1rem;
           column-gap: 1rem;
}
.gap-x-\[1\.875rem\] {
      -moz-column-gap: 1.875rem;
           column-gap: 1.875rem;
}
.gap-y-9 {
      row-gap: 2.25rem;
}
.gap-y-\[2\.5rem\] {
      row-gap: 2.5rem;
}
.space-x-3 > :not([hidden]) ~ :not([hidden]) {
      --tw-space-x-reverse: 0;
      margin-right: calc(0.75rem * var(--tw-space-x-reverse));
      margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-y-1 > :not([hidden]) ~ :not([hidden]) {
      --tw-space-y-reverse: 0;
      margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
      margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}
.space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
      --tw-space-y-reverse: 0;
      margin-top: calc(0.375rem * calc(1 - var(--tw-space-y-reverse)));
      margin-bottom: calc(0.375rem * var(--tw-space-y-reverse));
}
.space-y-2 > :not([hidden]) ~ :not([hidden]) {
      --tw-space-y-reverse: 0;
      margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
      margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}
.space-y-3 > :not([hidden]) ~ :not([hidden]) {
      --tw-space-y-reverse: 0;
      margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
      margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
}
.space-y-4 > :not([hidden]) ~ :not([hidden]) {
      --tw-space-y-reverse: 0;
      margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
      margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}
.space-y-6 > :not([hidden]) ~ :not([hidden]) {
      --tw-space-y-reverse: 0;
      margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
      margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}
.self-start {
      align-self: flex-start;
}
.self-end {
      align-self: flex-end;
}
.self-center {
      align-self: center;
}
.self-stretch {
      align-self: stretch;
}
.overflow-auto {
      overflow: auto;
}
.\!overflow-hidden {
      overflow: hidden !important;
}
.overflow-hidden {
      overflow: hidden;
}
.overflow-visible {
      overflow: visible;
}
.overflow-scroll {
      overflow: scroll;
}
.overflow-x-auto {
      overflow-x: auto;
}
.overflow-y-auto {
      overflow-y: auto;
}
.\!overflow-y-hidden {
      overflow-y: hidden !important;
}
.overflow-y-hidden {
      overflow-y: hidden;
}
.truncate {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
}
.text-ellipsis {
      text-overflow: ellipsis;
}
.whitespace-normal {
      white-space: normal;
}
.\!whitespace-nowrap {
      white-space: nowrap !important;
}
.whitespace-nowrap {
      white-space: nowrap;
}
.whitespace-pre-line {
      white-space: pre-line;
}
.break-words {
      overflow-wrap: break-word;
}
.\!rounded-2xl {
      border-radius: 1rem !important;
}
.\!rounded-3xl {
      border-radius: 1.5rem !important;
}
.\!rounded-\[0\.25rem\] {
      border-radius: 0.25rem !important;
}
.\!rounded-\[0\.625rem\] {
      border-radius: 0.625rem !important;
}
.\!rounded-\[0\.7rem\] {
      border-radius: 0.7rem !important;
}
.\!rounded-\[17\.92px\] {
      border-radius: 17.92px !important;
}
.\!rounded-full {
      border-radius: 9999px !important;
}
.\!rounded-lg {
      border-radius: 0.5rem !important;
}
.\!rounded-none {
      border-radius: 0px !important;
}
.\!rounded-xl {
      border-radius: 0.75rem !important;
}
.rounded {
      border-radius: 0.25rem;
}
.rounded-2xl {
      border-radius: 1rem;
}
.rounded-3xl {
      border-radius: 1.5rem;
}
.rounded-\[0\.25rem\] {
      border-radius: 0.25rem;
}
.rounded-\[0\.375rem\] {
      border-radius: 0.375rem;
}
.rounded-\[0\.5rem\] {
      border-radius: 0.5rem;
}
.rounded-\[0\.625rem\] {
      border-radius: 0.625rem;
}
.rounded-\[1\.25rem\] {
      border-radius: 1.25rem;
}
.rounded-\[10px\] {
      border-radius: 10px;
}
.rounded-\[18px\] {
      border-radius: 18px;
}
.rounded-\[2\.5rem\] {
      border-radius: 2.5rem;
}
.rounded-full {
      border-radius: 9999px;
}
.rounded-lg {
      border-radius: 0.5rem;
}
.rounded-md {
      border-radius: 0.375rem;
}
.rounded-sm {
      border-radius: 0.125rem;
}
.rounded-xl {
      border-radius: 0.75rem;
}
.\!rounded-l-3xl {
      border-top-left-radius: 1.5rem !important;
      border-bottom-left-radius: 1.5rem !important;
}
.\!rounded-r {
      border-top-right-radius: 0.25rem !important;
      border-bottom-right-radius: 0.25rem !important;
}
.\!rounded-r-3xl {
      border-top-right-radius: 1.5rem !important;
      border-bottom-right-radius: 1.5rem !important;
}
.rounded-b-2xl {
      border-bottom-right-radius: 1rem;
      border-bottom-left-radius: 1rem;
}
.rounded-e-full {
      border-start-end-radius: 9999px;
      border-end-end-radius: 9999px;
}
.rounded-l {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
}
.rounded-l-2xl {
      border-top-left-radius: 1rem;
      border-bottom-left-radius: 1rem;
}
.rounded-l-none {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
}
.rounded-r {
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
}
.rounded-r-2xl {
      border-top-right-radius: 1rem;
      border-bottom-right-radius: 1rem;
}
.rounded-r-none {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
}
.rounded-s-full {
      border-start-start-radius: 9999px;
      border-end-start-radius: 9999px;
}
.rounded-t-2xl {
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
}
.rounded-t-3xl {
      border-top-left-radius: 1.5rem;
      border-top-right-radius: 1.5rem;
}
.rounded-t-xl {
      border-top-left-radius: 0.75rem;
      border-top-right-radius: 0.75rem;
}
.rounded-bl-3xl {
      border-bottom-left-radius: 1.5rem;
}
.rounded-bl-lg {
      border-bottom-left-radius: 0.5rem;
}
.rounded-br-3xl {
      border-bottom-right-radius: 1.5rem;
}
.rounded-br-lg {
      border-bottom-right-radius: 0.5rem;
}
.\!border {
      border-width: 1px !important;
}
.\!border-0 {
      border-width: 0px !important;
}
.border {
      border-width: 1px;
}
.border-0 {
      border-width: 0px;
}
.border-2 {
      border-width: 2px;
}
.border-\[0\.125rem\] {
      border-width: 0.125rem;
}
.border-\[0\.1px\] {
      border-width: 0.1px;
}
.border-\[0\.25rem\] {
      border-width: 0.25rem;
}
.border-\[0\.896px\] {
      border-width: 0.896px;
}
.border-\[1px\] {
      border-width: 1px;
}
.border-\[2px\] {
      border-width: 2px;
}
.border-\[3px\] {
      border-width: 3px;
}
.border-y {
      border-top-width: 1px;
      border-bottom-width: 1px;
}
.border-y-\[0\.0625rem\] {
      border-top-width: 0.0625rem;
      border-bottom-width: 0.0625rem;
}
.\!border-b {
      border-bottom-width: 1px !important;
}
.\!border-l {
      border-left-width: 1px !important;
}
.\!border-l-0 {
      border-left-width: 0px !important;
}
.\!border-r {
      border-right-width: 1px !important;
}
.\!border-r-0 {
      border-right-width: 0px !important;
}
.border-b {
      border-bottom-width: 1px;
}
.border-b-0 {
      border-bottom-width: 0px;
}
.border-b-\[1\.5px\] {
      border-bottom-width: 1.5px;
}
.border-l {
      border-left-width: 1px;
}
.border-l-0 {
      border-left-width: 0px;
}
.border-r {
      border-right-width: 1px;
}
.border-r-0 {
      border-right-width: 0px;
}
.border-r-2 {
      border-right-width: 2px;
}
.border-s {
      border-inline-start-width: 1px;
}
.border-t {
      border-top-width: 1px;
}
.border-t-0 {
      border-top-width: 0px;
}
.\!border-solid {
      border-style: solid !important;
}
.border-solid {
      border-style: solid;
}
.border-dashed {
      border-style: dashed;
}
.\!border-none {
      border-style: none !important;
}
.border-none {
      border-style: none;
}
.\!border-\[\#F0FDF5\] {
      --tw-border-opacity: 1 !important;
      border-color: rgb(240 253 245 / var(--tw-border-opacity)) !important;
}
.\!border-blue-200 {
      --tw-border-opacity: 1 !important;
      border-color: rgb(191 219 254 / var(--tw-border-opacity)) !important;
}
.\!border-blue-300 {
      --tw-border-opacity: 1 !important;
      border-color: rgb(147 197 253 / var(--tw-border-opacity)) !important;
}
.\!border-blue-400 {
      --tw-border-opacity: 1 !important;
      border-color: rgb(96 165 250 / var(--tw-border-opacity)) !important;
}
.\!border-gray-100 {
      --tw-border-opacity: 1 !important;
      border-color: rgb(243 244 246 / var(--tw-border-opacity)) !important;
}
.\!border-gray-200 {
      --tw-border-opacity: 1 !important;
      border-color: rgb(229 231 235 / var(--tw-border-opacity)) !important;
}
.\!border-gray-300 {
      --tw-border-opacity: 1 !important;
      border-color: rgb(209 213 219 / var(--tw-border-opacity)) !important;
}
.\!border-gray-400 {
      --tw-border-opacity: 1 !important;
      border-color: rgb(156 163 175 / var(--tw-border-opacity)) !important;
}
.\!border-gray-500 {
      --tw-border-opacity: 1 !important;
      border-color: rgb(107 114 128 / var(--tw-border-opacity)) !important;
}
.\!border-gray-800 {
      --tw-border-opacity: 1 !important;
      border-color: rgb(31 41 55 / var(--tw-border-opacity)) !important;
}
.\!border-green-300 {
      --tw-border-opacity: 1 !important;
      border-color: rgb(134 239 172 / var(--tw-border-opacity)) !important;
}
.\!border-lime-200 {
      --tw-border-opacity: 1 !important;
      border-color: rgb(217 249 157 / var(--tw-border-opacity)) !important;
}
.\!border-neutral-200 {
      --tw-border-opacity: 1 !important;
      border-color: rgb(229 229 229 / var(--tw-border-opacity)) !important;
}
.\!border-neutral300 {
      --tw-border-opacity: 1 !important;
      border-color: rgb(203 213 225 / var(--tw-border-opacity)) !important;
}
.\!border-orange-100 {
      --tw-border-opacity: 1 !important;
      border-color: rgb(255 237 213 / var(--tw-border-opacity)) !important;
}
.\!border-orange-200 {
      --tw-border-opacity: 1 !important;
      border-color: rgb(254 215 170 / var(--tw-border-opacity)) !important;
}
.\!border-orange-600 {
      --tw-border-opacity: 1 !important;
      border-color: rgb(234 88 12 / var(--tw-border-opacity)) !important;
}
.\!border-red-100 {
      --tw-border-opacity: 1 !important;
      border-color: rgb(254 226 226 / var(--tw-border-opacity)) !important;
}
.\!border-red-500 {
      --tw-border-opacity: 1 !important;
      border-color: rgb(239 68 68 / var(--tw-border-opacity)) !important;
}
.\!border-red500 {
      --tw-border-opacity: 1 !important;
      border-color: rgb(239 68 68 / var(--tw-border-opacity)) !important;
}
.\!border-teal-600 {
      --tw-border-opacity: 1 !important;
      border-color: rgb(13 148 136 / var(--tw-border-opacity)) !important;
}
.\!border-violet-600 {
      --tw-border-opacity: 1 !important;
      border-color: rgb(124 58 237 / var(--tw-border-opacity)) !important;
}
.\!border-white {
      --tw-border-opacity: 1 !important;
      border-color: rgb(255 255 255 / var(--tw-border-opacity)) !important;
}
.\!border-white\/50 {
      border-color: rgb(255 255 255 / 0.5) !important;
}
.\!border-yellow-500 {
      --tw-border-opacity: 1 !important;
      border-color: rgb(234 179 8 / var(--tw-border-opacity)) !important;
}
.border-\[\#1A193B\] {
      --tw-border-opacity: 1;
      border-color: rgb(26 25 59 / var(--tw-border-opacity));
}
.border-\[\#8a3fda\] {
      --tw-border-opacity: 1;
      border-color: rgb(138 63 218 / var(--tw-border-opacity));
}
.border-amber-200 {
      --tw-border-opacity: 1;
      border-color: rgb(253 230 138 / var(--tw-border-opacity));
}
.border-amber-600 {
      --tw-border-opacity: 1;
      border-color: rgb(217 119 6 / var(--tw-border-opacity));
}
.border-blue-100 {
      --tw-border-opacity: 1;
      border-color: rgb(219 234 254 / var(--tw-border-opacity));
}
.border-blue-200 {
      --tw-border-opacity: 1;
      border-color: rgb(191 219 254 / var(--tw-border-opacity));
}
.border-blue-300 {
      --tw-border-opacity: 1;
      border-color: rgb(147 197 253 / var(--tw-border-opacity));
}
.border-blue-50 {
      --tw-border-opacity: 1;
      border-color: rgb(239 246 255 / var(--tw-border-opacity));
}
.border-blue-500 {
      --tw-border-opacity: 1;
      border-color: rgb(59 130 246 / var(--tw-border-opacity));
}
.border-blue-700 {
      --tw-border-opacity: 1;
      border-color: rgb(29 78 216 / var(--tw-border-opacity));
}
.border-blue-800 {
      --tw-border-opacity: 1;
      border-color: rgb(30 64 175 / var(--tw-border-opacity));
}
.border-blue900 {
      --tw-border-opacity: 1;
      border-color: rgb(30 58 138 / var(--tw-border-opacity));
}
.border-fuchsia-100 {
      --tw-border-opacity: 1;
      border-color: rgb(250 232 255 / var(--tw-border-opacity));
}
.border-fuchsia-200 {
      --tw-border-opacity: 1;
      border-color: rgb(245 208 254 / var(--tw-border-opacity));
}
.border-gray-100 {
      --tw-border-opacity: 1;
      border-color: rgb(243 244 246 / var(--tw-border-opacity));
}
.border-gray-200 {
      --tw-border-opacity: 1;
      border-color: rgb(229 231 235 / var(--tw-border-opacity));
}
.border-gray-300 {
      --tw-border-opacity: 1;
      border-color: rgb(209 213 219 / var(--tw-border-opacity));
}
.border-gray-400 {
      --tw-border-opacity: 1;
      border-color: rgb(156 163 175 / var(--tw-border-opacity));
}
.border-gray-700 {
      --tw-border-opacity: 1;
      border-color: rgb(55 65 81 / var(--tw-border-opacity));
}
.border-gray-800 {
      --tw-border-opacity: 1;
      border-color: rgb(31 41 55 / var(--tw-border-opacity));
}
.border-green-100 {
      --tw-border-opacity: 1;
      border-color: rgb(220 252 231 / var(--tw-border-opacity));
}
.border-green-300 {
      --tw-border-opacity: 1;
      border-color: rgb(134 239 172 / var(--tw-border-opacity));
}
.border-green-600 {
      --tw-border-opacity: 1;
      border-color: rgb(22 163 74 / var(--tw-border-opacity));
}
.border-green500 {
      --tw-border-opacity: 1;
      border-color: rgb(16 185 129 / var(--tw-border-opacity));
}
.border-grey100 {
      --tw-border-opacity: 1;
      border-color: rgb(217 217 217 / var(--tw-border-opacity));
}
.border-grey200 {
      --tw-border-opacity: 1;
      border-color: rgb(234 236 240 / var(--tw-border-opacity));
}
.border-grey400 {
      --tw-border-opacity: 1;
      border-color: rgb(156 163 175 / var(--tw-border-opacity));
}
.border-indigo-900 {
      --tw-border-opacity: 1;
      border-color: rgb(49 46 129 / var(--tw-border-opacity));
}
.border-lime-100 {
      --tw-border-opacity: 1;
      border-color: rgb(236 252 203 / var(--tw-border-opacity));
}
.border-lime-200 {
      --tw-border-opacity: 1;
      border-color: rgb(217 249 157 / var(--tw-border-opacity));
}
.border-lime-300 {
      --tw-border-opacity: 1;
      border-color: rgb(190 242 100 / var(--tw-border-opacity));
}
.border-lime-600 {
      --tw-border-opacity: 1;
      border-color: rgb(101 163 13 / var(--tw-border-opacity));
}
.border-neutral-100 {
      --tw-border-opacity: 1;
      border-color: rgb(245 245 245 / var(--tw-border-opacity));
}
.border-neutral-200 {
      --tw-border-opacity: 1;
      border-color: rgb(229 229 229 / var(--tw-border-opacity));
}
.border-neutral-300 {
      --tw-border-opacity: 1;
      border-color: rgb(212 212 212 / var(--tw-border-opacity));
}
.border-neutral-400 {
      --tw-border-opacity: 1;
      border-color: rgb(163 163 163 / var(--tw-border-opacity));
}
.border-neutral-500 {
      --tw-border-opacity: 1;
      border-color: rgb(115 115 115 / var(--tw-border-opacity));
}
.border-neutral100 {
      --tw-border-opacity: 1;
      border-color: rgb(241 245 249 / var(--tw-border-opacity));
}
.border-neutral200 {
      --tw-border-opacity: 1;
      border-color: rgb(226 232 240 / var(--tw-border-opacity));
}
.border-neutral300 {
      --tw-border-opacity: 1;
      border-color: rgb(203 213 225 / var(--tw-border-opacity));
}
.border-orange-100 {
      --tw-border-opacity: 1;
      border-color: rgb(255 237 213 / var(--tw-border-opacity));
}
.border-orange-200 {
      --tw-border-opacity: 1;
      border-color: rgb(254 215 170 / var(--tw-border-opacity));
}
.border-pink-100 {
      --tw-border-opacity: 1;
      border-color: rgb(252 231 243 / var(--tw-border-opacity));
}
.border-purple-200 {
      --tw-border-opacity: 1;
      border-color: rgb(233 213 255 / var(--tw-border-opacity));
}
.border-red-100 {
      --tw-border-opacity: 1;
      border-color: rgb(254 226 226 / var(--tw-border-opacity));
}
.border-red-200 {
      --tw-border-opacity: 1;
      border-color: rgb(254 202 202 / var(--tw-border-opacity));
}
.border-red-300 {
      --tw-border-opacity: 1;
      border-color: rgb(252 165 165 / var(--tw-border-opacity));
}
.border-red-500 {
      --tw-border-opacity: 1;
      border-color: rgb(239 68 68 / var(--tw-border-opacity));
}
.border-red500 {
      --tw-border-opacity: 1;
      border-color: rgb(239 68 68 / var(--tw-border-opacity));
}
.border-red600 {
      --tw-border-opacity: 1;
      border-color: rgb(220 38 38 / var(--tw-border-opacity));
}
.border-slate-200 {
      --tw-border-opacity: 1;
      border-color: rgb(226 232 240 / var(--tw-border-opacity));
}
.border-slate-300 {
      --tw-border-opacity: 1;
      border-color: rgb(203 213 225 / var(--tw-border-opacity));
}
.border-teal-100 {
      --tw-border-opacity: 1;
      border-color: rgb(204 251 241 / var(--tw-border-opacity));
}
.border-teal-200 {
      --tw-border-opacity: 1;
      border-color: rgb(153 246 228 / var(--tw-border-opacity));
}
.border-teal-300 {
      --tw-border-opacity: 1;
      border-color: rgb(94 234 212 / var(--tw-border-opacity));
}
.border-teal-400 {
      --tw-border-opacity: 1;
      border-color: rgb(45 212 191 / var(--tw-border-opacity));
}
.border-teal-600 {
      --tw-border-opacity: 1;
      border-color: rgb(13 148 136 / var(--tw-border-opacity));
}
.border-violet-200 {
      --tw-border-opacity: 1;
      border-color: rgb(221 214 254 / var(--tw-border-opacity));
}
.border-violet-500 {
      --tw-border-opacity: 1;
      border-color: rgb(139 92 246 / var(--tw-border-opacity));
}
.border-violet-600 {
      --tw-border-opacity: 1;
      border-color: rgb(124 58 237 / var(--tw-border-opacity));
}
.border-white {
      --tw-border-opacity: 1;
      border-color: rgb(255 255 255 / var(--tw-border-opacity));
}
.border-yellow-100 {
      --tw-border-opacity: 1;
      border-color: rgb(254 249 195 / var(--tw-border-opacity));
}
.border-yellow-300 {
      --tw-border-opacity: 1;
      border-color: rgb(253 224 71 / var(--tw-border-opacity));
}
.border-x-transparent {
      border-left-color: transparent;
      border-right-color: transparent;
}
.border-y-slate-300 {
      --tw-border-opacity: 1;
      border-top-color: rgb(203 213 225 / var(--tw-border-opacity));
      border-bottom-color: rgb(203 213 225 / var(--tw-border-opacity));
}
.\!border-b-gray-200 {
      --tw-border-opacity: 1 !important;
      border-bottom-color: rgb(229 231 235 / var(--tw-border-opacity)) !important;
}
.border-b-gray-200 {
      --tw-border-opacity: 1;
      border-bottom-color: rgb(229 231 235 / var(--tw-border-opacity));
}
.border-b-neutral-300 {
      --tw-border-opacity: 1;
      border-bottom-color: rgb(212 212 212 / var(--tw-border-opacity));
}
.border-l-gray-200 {
      --tw-border-opacity: 1;
      border-left-color: rgb(229 231 235 / var(--tw-border-opacity));
}
.border-l-gray-300 {
      --tw-border-opacity: 1;
      border-left-color: rgb(209 213 219 / var(--tw-border-opacity));
}
.\!bg-\[\#0A0929\] {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(10 9 41 / var(--tw-bg-opacity)) !important;
}
.\!bg-\[\#100F2E\] {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(16 15 46 / var(--tw-bg-opacity)) !important;
}
.\!bg-\[\#141431\] {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(20 20 49 / var(--tw-bg-opacity)) !important;
}
.\!bg-\[\#1A193B\] {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(26 25 59 / var(--tw-bg-opacity)) !important;
}
.\!bg-\[\#1a193b\] {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(26 25 59 / var(--tw-bg-opacity)) !important;
}
.\!bg-\[\#212048\] {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(33 32 72 / var(--tw-bg-opacity)) !important;
}
.\!bg-\[\#8686860D\] {
      background-color: #8686860D !important;
}
.\!bg-\[\#F0FDF5\] {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(240 253 245 / var(--tw-bg-opacity)) !important;
}
.\!bg-\[\#eff6ffbf\] {
      background-color: #eff6ffbf !important;
}
.\!bg-\[\#ffffff10\] {
      background-color: #ffffff10 !important;
}
.\!bg-\[rgb\(16\2c 14\2c 46\)\] {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(16 14 46 / var(--tw-bg-opacity)) !important;
}
.\!bg-\[unset\] {
      background-color: unset !important;
}
.\!bg-amber-100 {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(254 243 199 / var(--tw-bg-opacity)) !important;
}
.\!bg-amber-200 {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(253 230 138 / var(--tw-bg-opacity)) !important;
}
.\!bg-amber-50 {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(255 251 235 / var(--tw-bg-opacity)) !important;
}
.\!bg-amber-600 {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(217 119 6 / var(--tw-bg-opacity)) !important;
}
.\!bg-black {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(0 0 0 / var(--tw-bg-opacity)) !important;
}
.\!bg-blue-100 {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(219 234 254 / var(--tw-bg-opacity)) !important;
}
.\!bg-blue-200 {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(191 219 254 / var(--tw-bg-opacity)) !important;
}
.\!bg-blue-50 {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(239 246 255 / var(--tw-bg-opacity)) !important;
}
.\!bg-blue-50\/75 {
      background-color: rgb(239 246 255 / 0.75) !important;
}
.\!bg-blue-900 {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(30 58 138 / var(--tw-bg-opacity)) !important;
}
.\!bg-blue50 {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(239 246 255 / var(--tw-bg-opacity)) !important;
}
.\!bg-gray-100 {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(243 244 246 / var(--tw-bg-opacity)) !important;
}
.\!bg-gray-200 {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(229 231 235 / var(--tw-bg-opacity)) !important;
}
.\!bg-gray-300 {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(209 213 219 / var(--tw-bg-opacity)) !important;
}
.\!bg-gray-50 {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(249 250 251 / var(--tw-bg-opacity)) !important;
}
.\!bg-gray-600 {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(75 85 99 / var(--tw-bg-opacity)) !important;
}
.\!bg-gray-800 {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(31 41 55 / var(--tw-bg-opacity)) !important;
}
.\!bg-indigo-100 {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(224 231 255 / var(--tw-bg-opacity)) !important;
}
.\!bg-indigo-300\/50 {
      background-color: rgb(165 180 252 / 0.5) !important;
}
.\!bg-indigo-400 {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(129 140 248 / var(--tw-bg-opacity)) !important;
}
.\!bg-indigo-950 {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(30 27 75 / var(--tw-bg-opacity)) !important;
}
.\!bg-lime-100 {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(236 252 203 / var(--tw-bg-opacity)) !important;
}
.\!bg-lime-200 {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(217 249 157 / var(--tw-bg-opacity)) !important;
}
.\!bg-lime-50 {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(247 254 231 / var(--tw-bg-opacity)) !important;
}
.\!bg-lime-600 {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(101 163 13 / var(--tw-bg-opacity)) !important;
}
.\!bg-orange-100 {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(255 237 213 / var(--tw-bg-opacity)) !important;
}
.\!bg-orange-50 {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(255 247 237 / var(--tw-bg-opacity)) !important;
}
.\!bg-red-50 {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(254 242 242 / var(--tw-bg-opacity)) !important;
}
.\!bg-red50 {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(254 242 242 / var(--tw-bg-opacity)) !important;
}
.\!bg-teal-100 {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(204 251 241 / var(--tw-bg-opacity)) !important;
}
.\!bg-teal-50 {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(240 253 250 / var(--tw-bg-opacity)) !important;
}
.\!bg-teal-700 {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(15 118 110 / var(--tw-bg-opacity)) !important;
}
.\!bg-transparent {
      background-color: transparent !important;
}
.\!bg-violet-100 {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(237 233 254 / var(--tw-bg-opacity)) !important;
}
.\!bg-violet-200 {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(221 214 254 / var(--tw-bg-opacity)) !important;
}
.\!bg-violet-50 {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(245 243 255 / var(--tw-bg-opacity)) !important;
}
.\!bg-violet-600 {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(124 58 237 / var(--tw-bg-opacity)) !important;
}
.\!bg-white {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}
.\!bg-white\/50 {
      background-color: rgb(255 255 255 / 0.5) !important;
}
.\!bg-yellow-50 {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(254 252 232 / var(--tw-bg-opacity)) !important;
}
.bg-\[\#00000011\] {
      background-color: #00000011;
}
.bg-\[\#0008\] {
      background-color: #0008;
}
.bg-\[\#0A0929\] {
      --tw-bg-opacity: 1;
      background-color: rgb(10 9 41 / var(--tw-bg-opacity));
}
.bg-\[\#0F0F27\] {
      --tw-bg-opacity: 1;
      background-color: rgb(15 15 39 / var(--tw-bg-opacity));
}
.bg-\[\#100F2E\] {
      --tw-bg-opacity: 1;
      background-color: rgb(16 15 46 / var(--tw-bg-opacity));
}
.bg-\[\#161532\] {
      --tw-bg-opacity: 1;
      background-color: rgb(22 21 50 / var(--tw-bg-opacity));
}
.bg-\[\#1A193B\] {
      --tw-bg-opacity: 1;
      background-color: rgb(26 25 59 / var(--tw-bg-opacity));
}
.bg-\[\#212048\] {
      --tw-bg-opacity: 1;
      background-color: rgb(33 32 72 / var(--tw-bg-opacity));
}
.bg-\[\#32316080\] {
      background-color: #32316080;
}
.bg-\[\#8686860D\] {
      background-color: #8686860D;
}
.bg-\[\#93C5FD\] {
      --tw-bg-opacity: 1;
      background-color: rgb(147 197 253 / var(--tw-bg-opacity));
}
.bg-\[\#ECECF2\] {
      --tw-bg-opacity: 1;
      background-color: rgb(236 236 242 / var(--tw-bg-opacity));
}
.bg-\[\#EFF6FF\] {
      --tw-bg-opacity: 1;
      background-color: rgb(239 246 255 / var(--tw-bg-opacity));
}
.bg-\[\#FEC8C888\] {
      background-color: #FEC8C888;
}
.bg-\[\#ffffff01\] {
      background-color: #ffffff01;
}
.bg-\[rgb\(16\2c 14\2c 46\)\] {
      --tw-bg-opacity: 1;
      background-color: rgb(16 14 46 / var(--tw-bg-opacity));
}
.bg-\[rgb\(16\2c 15\2c 46\)\] {
      --tw-bg-opacity: 1;
      background-color: rgb(16 15 46 / var(--tw-bg-opacity));
}
.bg-\[rgb\(33\2c 32\2c 71\)\] {
      --tw-bg-opacity: 1;
      background-color: rgb(33 32 71 / var(--tw-bg-opacity));
}
.bg-\[rgba\(26\2c 25\2c 59\2c 0\.75\)\] {
      background-color: rgba(26,25,59,0.75);
}
.bg-amber-200 {
      --tw-bg-opacity: 1;
      background-color: rgb(253 230 138 / var(--tw-bg-opacity));
}
.bg-amber-50 {
      --tw-bg-opacity: 1;
      background-color: rgb(255 251 235 / var(--tw-bg-opacity));
}
.bg-amber-600 {
      --tw-bg-opacity: 1;
      background-color: rgb(217 119 6 / var(--tw-bg-opacity));
}
.bg-black {
      --tw-bg-opacity: 1;
      background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}
.bg-blue-100 {
      --tw-bg-opacity: 1;
      background-color: rgb(219 234 254 / var(--tw-bg-opacity));
}
.bg-blue-200 {
      --tw-bg-opacity: 1;
      background-color: rgb(191 219 254 / var(--tw-bg-opacity));
}
.bg-blue-50 {
      --tw-bg-opacity: 1;
      background-color: rgb(239 246 255 / var(--tw-bg-opacity));
}
.bg-blue-800 {
      --tw-bg-opacity: 1;
      background-color: rgb(30 64 175 / var(--tw-bg-opacity));
}
.bg-blue-900 {
      --tw-bg-opacity: 1;
      background-color: rgb(30 58 138 / var(--tw-bg-opacity));
}
.bg-blue100 {
      --tw-bg-opacity: 1;
      background-color: rgb(219 234 254 / var(--tw-bg-opacity));
}
.bg-blue50 {
      --tw-bg-opacity: 1;
      background-color: rgb(239 246 255 / var(--tw-bg-opacity));
}
.bg-blue800 {
      --tw-bg-opacity: 1;
      background-color: rgb(30 64 175 / var(--tw-bg-opacity));
}
.bg-blue900 {
      --tw-bg-opacity: 1;
      background-color: rgb(30 58 138 / var(--tw-bg-opacity));
}
.bg-fuchsia-200 {
      --tw-bg-opacity: 1;
      background-color: rgb(245 208 254 / var(--tw-bg-opacity));
}
.bg-fuchsia-50 {
      --tw-bg-opacity: 1;
      background-color: rgb(253 244 255 / var(--tw-bg-opacity));
}
.bg-gray-100 {
      --tw-bg-opacity: 1;
      background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}
.bg-gray-200 {
      --tw-bg-opacity: 1;
      background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}
.bg-gray-300 {
      --tw-bg-opacity: 1;
      background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}
.bg-gray-50 {
      --tw-bg-opacity: 1;
      background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}
.bg-gray-800 {
      --tw-bg-opacity: 1;
      background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}
.bg-green-200 {
      --tw-bg-opacity: 1;
      background-color: rgb(187 247 208 / var(--tw-bg-opacity));
}
.bg-green-50 {
      --tw-bg-opacity: 1;
      background-color: rgb(240 253 244 / var(--tw-bg-opacity));
}
.bg-green-500 {
      --tw-bg-opacity: 1;
      background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}
.bg-green-600 {
      --tw-bg-opacity: 1;
      background-color: rgb(22 163 74 / var(--tw-bg-opacity));
}
.bg-green50 {
      --tw-bg-opacity: 1;
      background-color: rgb(236 253 245 / var(--tw-bg-opacity));
}
.bg-grey300 {
      --tw-bg-opacity: 1;
      background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}
.bg-indigo-100 {
      --tw-bg-opacity: 1;
      background-color: rgb(224 231 255 / var(--tw-bg-opacity));
}
.bg-indigo-400 {
      --tw-bg-opacity: 1;
      background-color: rgb(129 140 248 / var(--tw-bg-opacity));
}
.bg-indigo-50 {
      --tw-bg-opacity: 1;
      background-color: rgb(238 242 255 / var(--tw-bg-opacity));
}
.bg-indigo-950 {
      --tw-bg-opacity: 1;
      background-color: rgb(30 27 75 / var(--tw-bg-opacity));
}
.bg-inherit {
      background-color: inherit;
}
.bg-lime-100 {
      --tw-bg-opacity: 1;
      background-color: rgb(236 252 203 / var(--tw-bg-opacity));
}
.bg-lime-200 {
      --tw-bg-opacity: 1;
      background-color: rgb(217 249 157 / var(--tw-bg-opacity));
}
.bg-lime-300 {
      --tw-bg-opacity: 1;
      background-color: rgb(190 242 100 / var(--tw-bg-opacity));
}
.bg-lime-50 {
      --tw-bg-opacity: 1;
      background-color: rgb(247 254 231 / var(--tw-bg-opacity));
}
.bg-lime-600 {
      --tw-bg-opacity: 1;
      background-color: rgb(101 163 13 / var(--tw-bg-opacity));
}
.bg-neutral-100 {
      --tw-bg-opacity: 1;
      background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}
.bg-neutral-200 {
      --tw-bg-opacity: 1;
      background-color: rgb(229 229 229 / var(--tw-bg-opacity));
}
.bg-neutral-50 {
      --tw-bg-opacity: 1;
      background-color: rgb(250 250 250 / var(--tw-bg-opacity));
}
.bg-neutral100 {
      --tw-bg-opacity: 1;
      background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}
.bg-neutral300 {
      --tw-bg-opacity: 1;
      background-color: rgb(203 213 225 / var(--tw-bg-opacity));
}
.bg-orange-100 {
      --tw-bg-opacity: 1;
      background-color: rgb(255 237 213 / var(--tw-bg-opacity));
}
.bg-orange-50 {
      --tw-bg-opacity: 1;
      background-color: rgb(255 247 237 / var(--tw-bg-opacity));
}
.bg-orange-600 {
      --tw-bg-opacity: 1;
      background-color: rgb(234 88 12 / var(--tw-bg-opacity));
}
.bg-orange-700 {
      --tw-bg-opacity: 1;
      background-color: rgb(194 65 12 / var(--tw-bg-opacity));
}
.bg-pink-100 {
      --tw-bg-opacity: 1;
      background-color: rgb(252 231 243 / var(--tw-bg-opacity));
}
.bg-pink-50 {
      --tw-bg-opacity: 1;
      background-color: rgb(253 242 248 / var(--tw-bg-opacity));
}
.bg-purple-100 {
      --tw-bg-opacity: 1;
      background-color: rgb(243 232 255 / var(--tw-bg-opacity));
}
.bg-red-100 {
      --tw-bg-opacity: 1;
      background-color: rgb(254 226 226 / var(--tw-bg-opacity));
}
.bg-red-200 {
      --tw-bg-opacity: 1;
      background-color: rgb(254 202 202 / var(--tw-bg-opacity));
}
.bg-red-50 {
      --tw-bg-opacity: 1;
      background-color: rgb(254 242 242 / var(--tw-bg-opacity));
}
.bg-red-500 {
      --tw-bg-opacity: 1;
      background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}
.bg-red-600 {
      --tw-bg-opacity: 1;
      background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}
.bg-red50 {
      --tw-bg-opacity: 1;
      background-color: rgb(254 242 242 / var(--tw-bg-opacity));
}
.bg-sky-50 {
      --tw-bg-opacity: 1;
      background-color: rgb(240 249 255 / var(--tw-bg-opacity));
}
.bg-slate-100 {
      --tw-bg-opacity: 1;
      background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}
.bg-slate-50 {
      --tw-bg-opacity: 1;
      background-color: rgb(248 250 252 / var(--tw-bg-opacity));
}
.bg-teal-100 {
      --tw-bg-opacity: 1;
      background-color: rgb(204 251 241 / var(--tw-bg-opacity));
}
.bg-teal-50 {
      --tw-bg-opacity: 1;
      background-color: rgb(240 253 250 / var(--tw-bg-opacity));
}
.bg-transparent {
      background-color: transparent;
}
.bg-violet-100 {
      --tw-bg-opacity: 1;
      background-color: rgb(237 233 254 / var(--tw-bg-opacity));
}
.bg-violet-200 {
      --tw-bg-opacity: 1;
      background-color: rgb(221 214 254 / var(--tw-bg-opacity));
}
.bg-violet-50 {
      --tw-bg-opacity: 1;
      background-color: rgb(245 243 255 / var(--tw-bg-opacity));
}
.bg-violet-600 {
      --tw-bg-opacity: 1;
      background-color: rgb(124 58 237 / var(--tw-bg-opacity));
}
.bg-white {
      --tw-bg-opacity: 1;
      background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.bg-white\/50 {
      background-color: rgb(255 255 255 / 0.5);
}
.bg-yellow-200 {
      --tw-bg-opacity: 1;
      background-color: rgb(254 240 138 / var(--tw-bg-opacity));
}
.bg-yellow-50 {
      --tw-bg-opacity: 1;
      background-color: rgb(254 252 232 / var(--tw-bg-opacity));
}
.bg-yellow-500 {
      --tw-bg-opacity: 1;
      background-color: rgb(234 179 8 / var(--tw-bg-opacity));
}
.bg-opacity-25 {
      --tw-bg-opacity: 0.25;
}
.bg-opacity-40 {
      --tw-bg-opacity: 0.4;
}
.\!bg-none {
      background-image: none !important;
}
.bg-\[radial-gradient\(circle_farthest-side_at_50\%_150\%\2c _\#572ca7_0\%\2c _\#6a3fda_55\%\2c _rgba\(30\2c 58\2c 138\2c 1\)_100\%\)\] {
      background-image: radial-gradient(circle farthest-side at 50% 150%, #572ca7 0%, #6a3fda 55%, rgba(30,58,138,1) 100%);
}
.bg-drawerBackdropGradient {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.38) 100%);
}
.bg-gradient-to-r {
      background-image: linear-gradient(to right, var(--tw-gradient-stops));
}
.bg-gradient-to-t {
      background-image: linear-gradient(to top, var(--tw-gradient-stops));
}
.bg-sliderBackdropGradient {
      background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.38) 100%);
}
.from-blue-100 {
      --tw-gradient-from: #dbeafe var(--tw-gradient-from-position);
      --tw-gradient-to: rgb(219 234 254 / 0) var(--tw-gradient-to-position);
      --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-blue-300 {
      --tw-gradient-from: #93c5fd var(--tw-gradient-from-position);
      --tw-gradient-to: rgb(147 197 253 / 0) var(--tw-gradient-to-position);
      --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-blue-800 {
      --tw-gradient-from: #1e40af var(--tw-gradient-from-position);
      --tw-gradient-to: rgb(30 64 175 / 0) var(--tw-gradient-to-position);
      --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-lime-600\/40 {
      --tw-gradient-from: rgb(101 163 13 / 0.4) var(--tw-gradient-from-position);
      --tw-gradient-to: rgb(101 163 13 / 0) var(--tw-gradient-to-position);
      --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-red-500\/40 {
      --tw-gradient-from: rgb(239 68 68 / 0.4) var(--tw-gradient-from-position);
      --tw-gradient-to: rgb(239 68 68 / 0) var(--tw-gradient-to-position);
      --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.via-indigo-200 {
      --tw-gradient-to: rgb(199 210 254 / 0)  var(--tw-gradient-to-position);
      --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe var(--tw-gradient-via-position), var(--tw-gradient-to);
}
.via-indigo-50 {
      --tw-gradient-to: rgb(238 242 255 / 0)  var(--tw-gradient-to-position);
      --tw-gradient-stops: var(--tw-gradient-from), #eef2ff var(--tw-gradient-via-position), var(--tw-gradient-to);
}
.via-indigo-600 {
      --tw-gradient-to: rgb(79 70 229 / 0)  var(--tw-gradient-to-position);
      --tw-gradient-stops: var(--tw-gradient-from), #4f46e5 var(--tw-gradient-via-position), var(--tw-gradient-to);
}
.to-lime-600\/40 {
      --tw-gradient-to: rgb(101 163 13 / 0.4) var(--tw-gradient-to-position);
}
.to-red-500\/40 {
      --tw-gradient-to: rgb(239 68 68 / 0.4) var(--tw-gradient-to-position);
}
.to-violet-200 {
      --tw-gradient-to: #ddd6fe var(--tw-gradient-to-position);
}
.to-violet-50 {
      --tw-gradient-to: #f5f3ff var(--tw-gradient-to-position);
}
.to-violet-800 {
      --tw-gradient-to: #5b21b6 var(--tw-gradient-to-position);
}
.bg-\[length\:100\%_150\%\] {
      background-size: 100% 150%;
}
.bg-cover {
      background-size: cover;
}
.bg-no-repeat {
      background-repeat: no-repeat;
}
.\!fill-\[\#0A0929\] {
      fill: #0A0929 !important;
}
.\!fill-gray-300 {
      fill: #d1d5db !important;
}
.fill-\[\#0D9488\] {
      fill: #0D9488;
}
.fill-\[\#16A34A\] {
      fill: #16A34A;
}
.fill-\[\#16a34a\] {
      fill: #16a34a;
}
.fill-\[\#1E293B\] {
      fill: #1E293B;
}
.fill-\[\#1E3A8A\] {
      fill: #1E3A8A;
}
.fill-\[\#1E40AF\] {
      fill: #1E40AF;
}
.fill-\[\#1e293b\] {
      fill: #1e293b;
}
.fill-\[\#1e3a8a\] {
      fill: #1e3a8a;
}
.fill-\[\#1e40af\] {
      fill: #1e40af;
}
.fill-\[\#3B82F6\] {
      fill: #3B82F6;
}
.fill-\[\#64748B\] {
      fill: #64748B;
}
.fill-\[\#955CF6\] {
      fill: #955CF6;
}
.fill-\[\#A3E635\] {
      fill: #A3E635;
}
.fill-\[\#DBEAFE\] {
      fill: #DBEAFE;
}
.fill-\[\#EA580C\] {
      fill: #EA580C;
}
.fill-\[\#EAB308\] {
      fill: #EAB308;
}
.fill-\[\#F4C430\] {
      fill: #F4C430;
}
.fill-\[\#F96416\] {
      fill: #F96416;
}
.fill-\[\#a21caf\] {
      fill: #a21caf;
}
.fill-\[\#bdffff\] {
      fill: #bdffff;
}
.fill-\[\#db2777\] {
      fill: #db2777;
}
.fill-\[\#ea580c\] {
      fill: #ea580c;
}
.fill-\[\#eff6ff\] {
      fill: #eff6ff;
}
.fill-\[blue100\] {
      fill: blue100;
}
.fill-amber-600 {
      fill: #d97706;
}
.fill-black {
      fill: #000;
}
.fill-blue-600 {
      fill: #2563eb;
}
.fill-blue-800 {
      fill: #1e40af;
}
.fill-blue-900 {
      fill: #1e3a8a;
}
.fill-blue100 {
      fill: #dbeafe;
}
.fill-blue800 {
      fill: #1e40af;
}
.fill-blue900 {
      fill: #1e3a8a;
}
.fill-fuchsia-400 {
      fill: #e879f9;
}
.fill-fuchsia-600 {
      fill: #c026d3;
}
.fill-gray-300 {
      fill: #d1d5db;
}
.fill-gray-400 {
      fill: #9ca3af;
}
.fill-gray-500 {
      fill: #6b7280;
}
.fill-gray-600 {
      fill: #4b5563;
}
.fill-gray-700 {
      fill: #374151;
}
.fill-green-600 {
      fill: #16a34a;
}
.fill-green-800 {
      fill: #166534;
}
.fill-indigo-300 {
      fill: #a5b4fc;
}
.fill-indigo-500 {
      fill: #6366f1;
}
.fill-indigo-900 {
      fill: #312e81;
}
.fill-lime-600 {
      fill: #65a30d;
}
.fill-neutral500 {
      fill: #64748b;
}
.fill-neutral600 {
      fill: #475569;
}
.fill-neutral800 {
      fill: #1e293b;
}
.fill-none {
      fill: none;
}
.fill-orange-500 {
      fill: #f97316;
}
.fill-orange-600 {
      fill: #ea580c;
}
.fill-red-500 {
      fill: #ef4444;
}
.fill-red-800 {
      fill: #991b1b;
}
.fill-red600 {
      fill: #dc2626;
}
.fill-teal-400 {
      fill: #2dd4bf;
}
.fill-teal-700 {
      fill: #0f766e;
}
.fill-violet-600 {
      fill: #7c3aed;
}
.fill-white {
      fill: #fff;
}
.fill-yellow-800 {
      fill: #854d0e;
}
.\!stroke-blue-800 {
      stroke: #1e40af !important;
}
.\!stroke-blue-900 {
      stroke: #1e3a8a !important;
}
.\!stroke-orange-600 {
      stroke: #ea580c !important;
}
.stroke-\[\#0D9488\] {
      stroke: #0D9488;
}
.stroke-\[\#1E293B\] {
      stroke: #1E293B;
}
.stroke-\[\#1E3A8A\] {
      stroke: #1E3A8A;
}
.stroke-\[\#1E40AF\] {
      stroke: #1E40AF;
}
.stroke-\[\#D1D5DB\] {
      stroke: #D1D5DB;
}
.stroke-\[\#DC2626\] {
      stroke: #DC2626;
}
.stroke-\[\#EA580C\] {
      stroke: #EA580C;
}
.stroke-\[\#F96416\] {
      stroke: #F96416;
}
.stroke-\[blue100\] {
      stroke: blue100;
}
.stroke-amber-600 {
      stroke: #d97706;
}
.stroke-black {
      stroke: #000;
}
.stroke-blue-800 {
      stroke: #1e40af;
}
.stroke-blue-900 {
      stroke: #1e3a8a;
}
.stroke-blue100 {
      stroke: #dbeafe;
}
.stroke-blue800 {
      stroke: #1e40af;
}
.stroke-blue900 {
      stroke: #1e3a8a;
}
.stroke-current {
      stroke: currentColor;
}
.stroke-fuchsia-700 {
      stroke: #a21caf;
}
.stroke-gray-200 {
      stroke: #e5e7eb;
}
.stroke-gray-300 {
      stroke: #d1d5db;
}
.stroke-gray-400 {
      stroke: #9ca3af;
}
.stroke-gray-500 {
      stroke: #6b7280;
}
.stroke-gray-700 {
      stroke: #374151;
}
.stroke-gray-800 {
      stroke: #1f2937;
}
.stroke-gray-900 {
      stroke: #111827;
}
.stroke-green-600 {
      stroke: #16a34a;
}
.stroke-grey500 {
      stroke: #6b7280;
}
.stroke-indigo-400 {
      stroke: #818cf8;
}
.stroke-indigo-600 {
      stroke: #4f46e5;
}
.stroke-indigo-900 {
      stroke: #312e81;
}
.stroke-lime-600 {
      stroke: #65a30d;
}
.stroke-lime-800 {
      stroke: #3f6212;
}
.stroke-neutral-300 {
      stroke: #d4d4d4;
}
.stroke-neutral600 {
      stroke: #475569;
}
.stroke-neutral800 {
      stroke: #1e293b;
}
.stroke-none {
      stroke: none;
}
.stroke-orange-600 {
      stroke: #ea580c;
}
.stroke-orange-700 {
      stroke: #c2410c;
}
.stroke-pink-600 {
      stroke: #db2777;
}
.stroke-pink-700 {
      stroke: #be185d;
}
.stroke-red-500 {
      stroke: #ef4444;
}
.stroke-red-600 {
      stroke: #dc2626;
}
.stroke-red-800 {
      stroke: #991b1b;
}
.stroke-sky-600 {
      stroke: #0284c7;
}
.stroke-teal-600 {
      stroke: #0d9488;
}
.stroke-teal-800 {
      stroke: #115e59;
}
.stroke-teal600 {
      stroke: #0d9488;
}
.stroke-transparent {
      stroke: transparent;
}
.stroke-violet-600 {
      stroke: #7c3aed;
}
.stroke-white {
      stroke: #fff;
}
.stroke-yellow-500 {
      stroke: #eab308;
}
.stroke-yellow-600 {
      stroke: #ca8a04;
}
.stroke-yellow-800 {
      stroke: #854d0e;
}
.stroke-1 {
      stroke-width: 1;
}
.object-contain {
      -o-object-fit: contain;
         object-fit: contain;
}
.object-cover {
      -o-object-fit: cover;
         object-fit: cover;
}
.object-fill {
      -o-object-fit: fill;
         object-fit: fill;
}
.\!p-0 {
      padding: 0px !important;
}
.\!p-1 {
      padding: 0.25rem !important;
}
.\!p-2 {
      padding: 0.5rem !important;
}
.\!p-3 {
      padding: 0.75rem !important;
}
.\!p-4 {
      padding: 1rem !important;
}
.\!p-5 {
      padding: 1.25rem !important;
}
.\!p-6 {
      padding: 1.5rem !important;
}
.\!p-\[0\.45rem\] {
      padding: 0.45rem !important;
}
.\!p-\[0\.5rem\] {
      padding: 0.5rem !important;
}
.\!p-\[0rem\] {
      padding: 0rem !important;
}
.\!p-\[6px\] {
      padding: 6px !important;
}
.p-0 {
      padding: 0px;
}
.p-0\.5 {
      padding: 0.125rem;
}
.p-1 {
      padding: 0.25rem;
}
.p-10 {
      padding: 2.5rem;
}
.p-2 {
      padding: 0.5rem;
}
.p-3 {
      padding: 0.75rem;
}
.p-4 {
      padding: 1rem;
}
.p-5 {
      padding: 1.25rem;
}
.p-6 {
      padding: 1.5rem;
}
.p-7 {
      padding: 1.75rem;
}
.p-8 {
      padding: 2rem;
}
.p-9 {
      padding: 2.25rem;
}
.p-\[0\.25rem\] {
      padding: 0.25rem;
}
.p-\[0\.2rem\] {
      padding: 0.2rem;
}
.p-\[0rem\] {
      padding: 0rem;
}
.p-\[1\.5rem\] {
      padding: 1.5rem;
}
.p-\[2\.33rem\] {
      padding: 2.33rem;
}
.p-\[2\.5rem\] {
      padding: 2.5rem;
}
.p-\[2px\] {
      padding: 2px;
}
.p-\[calc\(100\%-18rem\)\] {
      padding: calc(100% - 18rem);
}
.\!px-0 {
      padding-left: 0px !important;
      padding-right: 0px !important;
}
.\!px-2 {
      padding-left: 0.5rem !important;
      padding-right: 0.5rem !important;
}
.\!px-3 {
      padding-left: 0.75rem !important;
      padding-right: 0.75rem !important;
}
.\!px-4 {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
}
.\!px-6 {
      padding-left: 1.5rem !important;
      padding-right: 1.5rem !important;
}
.\!py-0 {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
}
.\!py-1 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
}
.\!py-1\.5 {
      padding-top: 0.375rem !important;
      padding-bottom: 0.375rem !important;
}
.\!py-2 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
}
.\!py-3 {
      padding-top: 0.75rem !important;
      padding-bottom: 0.75rem !important;
}
.\!py-4 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
}
.\!py-5 {
      padding-top: 1.25rem !important;
      padding-bottom: 1.25rem !important;
}
.\!py-6 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important;
}
.\!py-\[0\.38rem\] {
      padding-top: 0.38rem !important;
      padding-bottom: 0.38rem !important;
}
.\!py-\[6px\] {
      padding-top: 6px !important;
      padding-bottom: 6px !important;
}
.\!py-\[9\.5px\] {
      padding-top: 9.5px !important;
      padding-bottom: 9.5px !important;
}
.\!py-\[9px\] {
      padding-top: 9px !important;
      padding-bottom: 9px !important;
}
.px-0 {
      padding-left: 0px;
      padding-right: 0px;
}
.px-1 {
      padding-left: 0.25rem;
      padding-right: 0.25rem;
}
.px-10 {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
}
.px-2 {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
}
.px-2\.5 {
      padding-left: 0.625rem;
      padding-right: 0.625rem;
}
.px-3 {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
}
.px-4 {
      padding-left: 1rem;
      padding-right: 1rem;
}
.px-6 {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
}
.px-8 {
      padding-left: 2rem;
      padding-right: 2rem;
}
.px-\[0\.438rem\] {
      padding-left: 0.438rem;
      padding-right: 0.438rem;
}
.px-\[0\.625rem\] {
      padding-left: 0.625rem;
      padding-right: 0.625rem;
}
.px-\[0\.75rem\] {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
}
.px-\[0px\] {
      padding-left: 0px;
      padding-right: 0px;
}
.px-\[0rem\] {
      padding-left: 0rem;
      padding-right: 0rem;
}
.px-\[12px\] {
      padding-left: 12px;
      padding-right: 12px;
}
.px-\[1rem\] {
      padding-left: 1rem;
      padding-right: 1rem;
}
.px-\[3rem\] {
      padding-left: 3rem;
      padding-right: 3rem;
}
.px-\[55px\] {
      padding-left: 55px;
      padding-right: 55px;
}
.py-0 {
      padding-top: 0px;
      padding-bottom: 0px;
}
.py-0\.5 {
      padding-top: 0.125rem;
      padding-bottom: 0.125rem;
}
.py-1 {
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
}
.py-1\.5 {
      padding-top: 0.375rem;
      padding-bottom: 0.375rem;
}
.py-11 {
      padding-top: 2.75rem;
      padding-bottom: 2.75rem;
}
.py-12 {
      padding-top: 3rem;
      padding-bottom: 3rem;
}
.py-2 {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
}
.py-2\.5 {
      padding-top: 0.625rem;
      padding-bottom: 0.625rem;
}
.py-3 {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
}
.py-4 {
      padding-top: 1rem;
      padding-bottom: 1rem;
}
.py-6 {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
}
.py-8 {
      padding-top: 2rem;
      padding-bottom: 2rem;
}
.py-\[0\.125rem\] {
      padding-top: 0.125rem;
      padding-bottom: 0.125rem;
}
.py-\[0\.1875rem\] {
      padding-top: 0.1875rem;
      padding-bottom: 0.1875rem;
}
.py-\[0\.375rem\] {
      padding-top: 0.375rem;
      padding-bottom: 0.375rem;
}
.py-\[0\.438rem\] {
      padding-top: 0.438rem;
      padding-bottom: 0.438rem;
}
.py-\[0\.5rem\] {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
}
.py-\[0\.625rem\] {
      padding-top: 0.625rem;
      padding-bottom: 0.625rem;
}
.py-\[0\.75rem\] {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
}
.py-\[0\.938rem\] {
      padding-top: 0.938rem;
      padding-bottom: 0.938rem;
}
.py-\[1\.125rem\] {
      padding-top: 1.125rem;
      padding-bottom: 1.125rem;
}
.py-\[1rem\] {
      padding-top: 1rem;
      padding-bottom: 1rem;
}
.py-\[2\.5px\] {
      padding-top: 2.5px;
      padding-bottom: 2.5px;
}
.py-\[2px\] {
      padding-top: 2px;
      padding-bottom: 2px;
}
.py-\[8px\] {
      padding-top: 8px;
      padding-bottom: 8px;
}
.\!pb-\[5px\] {
      padding-bottom: 5px !important;
}
.\!ps-6 {
      padding-inline-start: 1.5rem !important;
}
.\!pt-4 {
      padding-top: 1rem !important;
}
.pb-10 {
      padding-bottom: 2.5rem;
}
.pb-16 {
      padding-bottom: 4rem;
}
.pb-2 {
      padding-bottom: 0.5rem;
}
.pb-3 {
      padding-bottom: 0.75rem;
}
.pb-4 {
      padding-bottom: 1rem;
}
.pb-6 {
      padding-bottom: 1.5rem;
}
.pb-\[0\.3125rem\] {
      padding-bottom: 0.3125rem;
}
.pb-\[18px\] {
      padding-bottom: 18px;
}
.pb-\[5px\] {
      padding-bottom: 5px;
}
.pe-0 {
      padding-inline-end: 0px;
}
.pl-1 {
      padding-left: 0.25rem;
}
.pl-12 {
      padding-left: 3rem;
}
.pl-2 {
      padding-left: 0.5rem;
}
.pl-4 {
      padding-left: 1rem;
}
.pl-6 {
      padding-left: 1.5rem;
}
.pr-2 {
      padding-right: 0.5rem;
}
.pr-3 {
      padding-right: 0.75rem;
}
.pr-4 {
      padding-right: 1rem;
}
.pr-5 {
      padding-right: 1.25rem;
}
.pr-6 {
      padding-right: 1.5rem;
}
.ps-1 {
      padding-inline-start: 0.25rem;
}
.ps-2 {
      padding-inline-start: 0.5rem;
}
.ps-3 {
      padding-inline-start: 0.75rem;
}
.ps-4 {
      padding-inline-start: 1rem;
}
.ps-6 {
      padding-inline-start: 1.5rem;
}
.pt-0 {
      padding-top: 0px;
}
.pt-1 {
      padding-top: 0.25rem;
}
.pt-2 {
      padding-top: 0.5rem;
}
.pt-4 {
      padding-top: 1rem;
}
.pt-40 {
      padding-top: 10rem;
}
.pt-6 {
      padding-top: 1.5rem;
}
.pt-\[0\.125rem\] {
      padding-top: 0.125rem;
}
.pt-\[1rem\] {
      padding-top: 1rem;
}
.text-left {
      text-align: left;
}
.text-center {
      text-align: center;
}
.text-right {
      text-align: right;
}
.text-justify {
      text-align: justify;
}
.\!text-start {
      text-align: start !important;
}
.text-start {
      text-align: start;
}
.text-end {
      text-align: end;
}
.align-middle {
      vertical-align: middle;
}
.\!font-aleo {
      font-family: Aleo, serif, var(--inter-font) !important;
}
.\!text-callout {
      font-size: 0.8125rem !important;
      line-height: 1.1875rem !important;
}
.\!text-container-text {
      font-size: 0.875rem !important;
      line-height: 1rem !important;
}
.\!text-xs {
      font-size: 0.75rem !important;
      line-height: 1rem !important;
}
.text-2xl {
      font-size: 1.5rem;
      line-height: 2rem;
}
.text-\[0\.75rem\] {
      font-size: 0.75rem;
}
.text-\[0\.7rem\] {
      font-size: 0.7rem;
}
.text-\[0\.8125rem\] {
      font-size: 0.8125rem;
}
.text-\[0\.875rem\] {
      font-size: 0.875rem;
}
.text-\[0\.8rem\] {
      font-size: 0.8rem;
}
.text-base {
      font-size: 1rem;
      line-height: 1.5rem;
}
.text-body {
      font-size: 0.875rem;
      line-height: 1.3125rem;
}
.text-callout {
      font-size: 0.8125rem;
      line-height: 1.1875rem;
}
.text-container-text {
      font-size: 0.875rem;
      line-height: 1rem;
}
.text-display1 {
      font-size: 2.125rem;
      line-height: 3.188rem;
}
.text-display2 {
      font-size: 1.875rem;
      line-height: 2.813rem;
}
.text-footnote {
      font-size: 0.6875rem;
      line-height: 1rem;
}
.text-heading1 {
      font-size: 1.125rem;
      line-height: 1.75rem;
}
.text-heading2 {
      font-size: 1rem;
      line-height: 1.5rem;
}
.text-sm {
      font-size: 0.875rem;
      line-height: 1.25rem;
}
.text-title1 {
      font-size: 1.625rem;
      line-height: 2.438rem;
}
.text-title2 {
      font-size: 1.4375rem;
      line-height: 2.125rem;
}
.text-xs {
      font-size: 0.75rem;
      line-height: 1rem;
}
.\!font-bold {
      font-weight: 700 !important;
}
.\!font-medium {
      font-weight: 500 !important;
}
.\!font-normal {
      font-weight: 400 !important;
}
.\!font-semibold {
      font-weight: 600 !important;
}
.font-bold {
      font-weight: 700;
}
.font-medium {
      font-weight: 500;
}
.font-normal {
      font-weight: 400;
}
.font-semibold {
      font-weight: 600;
}
.italic {
      font-style: italic;
}
.leading-4 {
      line-height: 1rem;
}
.leading-6 {
      line-height: 1.5rem;
}
.leading-\[0\] {
      line-height: 0;
}
.leading-\[1\.1875rem\] {
      line-height: 1.1875rem;
}
.leading-\[1\.315rem\] {
      line-height: 1.315rem;
}
.leading-\[17px\] {
      line-height: 17px;
}
.leading-\[19px\] {
      line-height: 19px;
}
.\!text-\[\#0D9488\] {
      --tw-text-opacity: 1 !important;
      color: rgb(13 148 136 / var(--tw-text-opacity)) !important;
}
.\!text-amber-600 {
      --tw-text-opacity: 1 !important;
      color: rgb(217 119 6 / var(--tw-text-opacity)) !important;
}
.\!text-blue-800 {
      --tw-text-opacity: 1 !important;
      color: rgb(30 64 175 / var(--tw-text-opacity)) !important;
}
.\!text-blue-900 {
      --tw-text-opacity: 1 !important;
      color: rgb(30 58 138 / var(--tw-text-opacity)) !important;
}
.\!text-blue800 {
      --tw-text-opacity: 1 !important;
      color: rgb(30 64 175 / var(--tw-text-opacity)) !important;
}
.\!text-blue900 {
      --tw-text-opacity: 1 !important;
      color: rgb(30 58 138 / var(--tw-text-opacity)) !important;
}
.\!text-gray-300 {
      --tw-text-opacity: 1 !important;
      color: rgb(209 213 219 / var(--tw-text-opacity)) !important;
}
.\!text-gray-400 {
      --tw-text-opacity: 1 !important;
      color: rgb(156 163 175 / var(--tw-text-opacity)) !important;
}
.\!text-gray-500 {
      --tw-text-opacity: 1 !important;
      color: rgb(107 114 128 / var(--tw-text-opacity)) !important;
}
.\!text-gray-600 {
      --tw-text-opacity: 1 !important;
      color: rgb(75 85 99 / var(--tw-text-opacity)) !important;
}
.\!text-gray-700 {
      --tw-text-opacity: 1 !important;
      color: rgb(55 65 81 / var(--tw-text-opacity)) !important;
}
.\!text-gray-800 {
      --tw-text-opacity: 1 !important;
      color: rgb(31 41 55 / var(--tw-text-opacity)) !important;
}
.\!text-green-600 {
      --tw-text-opacity: 1 !important;
      color: rgb(22 163 74 / var(--tw-text-opacity)) !important;
}
.\!text-indigo-300 {
      --tw-text-opacity: 1 !important;
      color: rgb(165 180 252 / var(--tw-text-opacity)) !important;
}
.\!text-indigo-400 {
      --tw-text-opacity: 1 !important;
      color: rgb(129 140 248 / var(--tw-text-opacity)) !important;
}
.\!text-indigo-950 {
      --tw-text-opacity: 1 !important;
      color: rgb(30 27 75 / var(--tw-text-opacity)) !important;
}
.\!text-lime-600 {
      --tw-text-opacity: 1 !important;
      color: rgb(101 163 13 / var(--tw-text-opacity)) !important;
}
.\!text-neutral-100 {
      --tw-text-opacity: 1 !important;
      color: rgb(245 245 245 / var(--tw-text-opacity)) !important;
}
.\!text-neutral-700 {
      --tw-text-opacity: 1 !important;
      color: rgb(64 64 64 / var(--tw-text-opacity)) !important;
}
.\!text-orange-600 {
      --tw-text-opacity: 1 !important;
      color: rgb(234 88 12 / var(--tw-text-opacity)) !important;
}
.\!text-red-500 {
      --tw-text-opacity: 1 !important;
      color: rgb(239 68 68 / var(--tw-text-opacity)) !important;
}
.\!text-teal-600 {
      --tw-text-opacity: 1 !important;
      color: rgb(13 148 136 / var(--tw-text-opacity)) !important;
}
.\!text-violet-600 {
      --tw-text-opacity: 1 !important;
      color: rgb(124 58 237 / var(--tw-text-opacity)) !important;
}
.\!text-white {
      --tw-text-opacity: 1 !important;
      color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}
.\!text-yellow-500 {
      --tw-text-opacity: 1 !important;
      color: rgb(234 179 8 / var(--tw-text-opacity)) !important;
}
.text-\[\#EF6C00\] {
      --tw-text-opacity: 1;
      color: rgb(239 108 0 / var(--tw-text-opacity));
}
.text-\[\#FF9800\] {
      --tw-text-opacity: 1;
      color: rgb(255 152 0 / var(--tw-text-opacity));
}
.text-\[\#fb9800\] {
      --tw-text-opacity: 1;
      color: rgb(251 152 0 / var(--tw-text-opacity));
}
.text-amber-600 {
      --tw-text-opacity: 1;
      color: rgb(217 119 6 / var(--tw-text-opacity));
}
.text-black {
      --tw-text-opacity: 1;
      color: rgb(0 0 0 / var(--tw-text-opacity));
}
.text-blue-300 {
      --tw-text-opacity: 1;
      color: rgb(147 197 253 / var(--tw-text-opacity));
}
.text-blue-50 {
      --tw-text-opacity: 1;
      color: rgb(239 246 255 / var(--tw-text-opacity));
}
.text-blue-700 {
      --tw-text-opacity: 1;
      color: rgb(29 78 216 / var(--tw-text-opacity));
}
.text-blue-800 {
      --tw-text-opacity: 1;
      color: rgb(30 64 175 / var(--tw-text-opacity));
}
.text-blue-900 {
      --tw-text-opacity: 1;
      color: rgb(30 58 138 / var(--tw-text-opacity));
}
.text-blue100 {
      --tw-text-opacity: 1;
      color: rgb(219 234 254 / var(--tw-text-opacity));
}
.text-blue800 {
      --tw-text-opacity: 1;
      color: rgb(30 64 175 / var(--tw-text-opacity));
}
.text-blue900 {
      --tw-text-opacity: 1;
      color: rgb(30 58 138 / var(--tw-text-opacity));
}
.text-fuchsia-400 {
      --tw-text-opacity: 1;
      color: rgb(232 121 249 / var(--tw-text-opacity));
}
.text-fuchsia-500 {
      --tw-text-opacity: 1;
      color: rgb(217 70 239 / var(--tw-text-opacity));
}
.text-fuchsia-700 {
      --tw-text-opacity: 1;
      color: rgb(162 28 175 / var(--tw-text-opacity));
}
.text-gray-200 {
      --tw-text-opacity: 1;
      color: rgb(229 231 235 / var(--tw-text-opacity));
}
.text-gray-300 {
      --tw-text-opacity: 1;
      color: rgb(209 213 219 / var(--tw-text-opacity));
}
.text-gray-400 {
      --tw-text-opacity: 1;
      color: rgb(156 163 175 / var(--tw-text-opacity));
}
.text-gray-50 {
      --tw-text-opacity: 1;
      color: rgb(249 250 251 / var(--tw-text-opacity));
}
.text-gray-500 {
      --tw-text-opacity: 1;
      color: rgb(107 114 128 / var(--tw-text-opacity));
}
.text-gray-600 {
      --tw-text-opacity: 1;
      color: rgb(75 85 99 / var(--tw-text-opacity));
}
.text-gray-700 {
      --tw-text-opacity: 1;
      color: rgb(55 65 81 / var(--tw-text-opacity));
}
.text-gray-800 {
      --tw-text-opacity: 1;
      color: rgb(31 41 55 / var(--tw-text-opacity));
}
.text-gray-900 {
      --tw-text-opacity: 1;
      color: rgb(17 24 39 / var(--tw-text-opacity));
}
.text-green-400 {
      --tw-text-opacity: 1;
      color: rgb(74 222 128 / var(--tw-text-opacity));
}
.text-green-500 {
      --tw-text-opacity: 1;
      color: rgb(34 197 94 / var(--tw-text-opacity));
}
.text-green-600 {
      --tw-text-opacity: 1;
      color: rgb(22 163 74 / var(--tw-text-opacity));
}
.text-green-800 {
      --tw-text-opacity: 1;
      color: rgb(22 101 52 / var(--tw-text-opacity));
}
.text-green500 {
      --tw-text-opacity: 1;
      color: rgb(16 185 129 / var(--tw-text-opacity));
}
.text-grey400 {
      --tw-text-opacity: 1;
      color: rgb(156 163 175 / var(--tw-text-opacity));
}
.text-grey500 {
      --tw-text-opacity: 1;
      color: rgb(107 114 128 / var(--tw-text-opacity));
}
.text-grey800 {
      --tw-text-opacity: 1;
      color: rgb(31 41 55 / var(--tw-text-opacity));
}
.text-indigo-300 {
      --tw-text-opacity: 1;
      color: rgb(165 180 252 / var(--tw-text-opacity));
}
.text-indigo-400 {
      --tw-text-opacity: 1;
      color: rgb(129 140 248 / var(--tw-text-opacity));
}
.text-indigo-500 {
      --tw-text-opacity: 1;
      color: rgb(99 102 241 / var(--tw-text-opacity));
}
.text-indigo-600 {
      --tw-text-opacity: 1;
      color: rgb(79 70 229 / var(--tw-text-opacity));
}
.text-indigo-700 {
      --tw-text-opacity: 1;
      color: rgb(67 56 202 / var(--tw-text-opacity));
}
.text-lime-400 {
      --tw-text-opacity: 1;
      color: rgb(163 230 53 / var(--tw-text-opacity));
}
.text-lime-600 {
      --tw-text-opacity: 1;
      color: rgb(101 163 13 / var(--tw-text-opacity));
}
.text-neutral-100 {
      --tw-text-opacity: 1;
      color: rgb(245 245 245 / var(--tw-text-opacity));
}
.text-neutral-300 {
      --tw-text-opacity: 1;
      color: rgb(212 212 212 / var(--tw-text-opacity));
}
.text-neutral-400 {
      --tw-text-opacity: 1;
      color: rgb(163 163 163 / var(--tw-text-opacity));
}
.text-neutral-600 {
      --tw-text-opacity: 1;
      color: rgb(82 82 82 / var(--tw-text-opacity));
}
.text-neutral-700 {
      --tw-text-opacity: 1;
      color: rgb(64 64 64 / var(--tw-text-opacity));
}
.text-neutral-800 {
      --tw-text-opacity: 1;
      color: rgb(38 38 38 / var(--tw-text-opacity));
}
.text-neutral-900 {
      --tw-text-opacity: 1;
      color: rgb(23 23 23 / var(--tw-text-opacity));
}
.text-neutral400 {
      --tw-text-opacity: 1;
      color: rgb(148 163 184 / var(--tw-text-opacity));
}
.text-neutral500 {
      --tw-text-opacity: 1;
      color: rgb(100 116 139 / var(--tw-text-opacity));
}
.text-neutral600 {
      --tw-text-opacity: 1;
      color: rgb(71 85 105 / var(--tw-text-opacity));
}
.text-neutral800 {
      --tw-text-opacity: 1;
      color: rgb(30 41 59 / var(--tw-text-opacity));
}
.text-orange-400 {
      --tw-text-opacity: 1;
      color: rgb(251 146 60 / var(--tw-text-opacity));
}
.text-orange-50 {
      --tw-text-opacity: 1;
      color: rgb(255 247 237 / var(--tw-text-opacity));
}
.text-orange-500 {
      --tw-text-opacity: 1;
      color: rgb(249 115 22 / var(--tw-text-opacity));
}
.text-orange-600 {
      --tw-text-opacity: 1;
      color: rgb(234 88 12 / var(--tw-text-opacity));
}
.text-orange-700 {
      --tw-text-opacity: 1;
      color: rgb(194 65 12 / var(--tw-text-opacity));
}
.text-orange600 {
      --tw-text-opacity: 1;
      color: rgb(234 88 12 / var(--tw-text-opacity));
}
.text-pink-600 {
      --tw-text-opacity: 1;
      color: rgb(219 39 119 / var(--tw-text-opacity));
}
.text-pink-700 {
      --tw-text-opacity: 1;
      color: rgb(190 24 93 / var(--tw-text-opacity));
}
.text-purple-600 {
      --tw-text-opacity: 1;
      color: rgb(147 51 234 / var(--tw-text-opacity));
}
.text-red-300 {
      --tw-text-opacity: 1;
      color: rgb(252 165 165 / var(--tw-text-opacity));
}
.text-red-400 {
      --tw-text-opacity: 1;
      color: rgb(248 113 113 / var(--tw-text-opacity));
}
.text-red-500 {
      --tw-text-opacity: 1;
      color: rgb(239 68 68 / var(--tw-text-opacity));
}
.text-red-600 {
      --tw-text-opacity: 1;
      color: rgb(220 38 38 / var(--tw-text-opacity));
}
.text-red500 {
      --tw-text-opacity: 1;
      color: rgb(239 68 68 / var(--tw-text-opacity));
}
.text-sky-600 {
      --tw-text-opacity: 1;
      color: rgb(2 132 199 / var(--tw-text-opacity));
}
.text-slate-600 {
      --tw-text-opacity: 1;
      color: rgb(71 85 105 / var(--tw-text-opacity));
}
.text-slate-800 {
      --tw-text-opacity: 1;
      color: rgb(30 41 59 / var(--tw-text-opacity));
}
.text-teal-300 {
      --tw-text-opacity: 1;
      color: rgb(94 234 212 / var(--tw-text-opacity));
}
.text-teal-400 {
      --tw-text-opacity: 1;
      color: rgb(45 212 191 / var(--tw-text-opacity));
}
.text-teal-600 {
      --tw-text-opacity: 1;
      color: rgb(13 148 136 / var(--tw-text-opacity));
}
.text-teal-700 {
      --tw-text-opacity: 1;
      color: rgb(15 118 110 / var(--tw-text-opacity));
}
.text-teal600 {
      --tw-text-opacity: 1;
      color: rgb(13 148 136 / var(--tw-text-opacity));
}
.text-violet-500 {
      --tw-text-opacity: 1;
      color: rgb(139 92 246 / var(--tw-text-opacity));
}
.text-violet-600 {
      --tw-text-opacity: 1;
      color: rgb(124 58 237 / var(--tw-text-opacity));
}
.text-white {
      --tw-text-opacity: 1;
      color: rgb(255 255 255 / var(--tw-text-opacity));
}
.text-yellow-300 {
      --tw-text-opacity: 1;
      color: rgb(253 224 71 / var(--tw-text-opacity));
}
.text-yellow-500 {
      --tw-text-opacity: 1;
      color: rgb(234 179 8 / var(--tw-text-opacity));
}
.text-yellow-600 {
      --tw-text-opacity: 1;
      color: rgb(202 138 4 / var(--tw-text-opacity));
}
.text-yellow-800 {
      --tw-text-opacity: 1;
      color: rgb(133 77 14 / var(--tw-text-opacity));
}
.underline {
      text-decoration-line: underline;
}
.no-underline {
      text-decoration-line: none;
}
.decoration-blue-800 {
      text-decoration-color: #1e40af;
}
.decoration-blue900 {
      text-decoration-color: #1e3a8a;
}
.decoration-2 {
      text-decoration-thickness: 2px;
}
.\!accent-teal-600 {
      accent-color: #0d9488 !important;
}
.accent-blue800 {
      accent-color: #1e40af;
}
.opacity-0 {
      opacity: 0;
}
.opacity-100 {
      opacity: 1;
}
.opacity-50 {
      opacity: 0.5;
}
.opacity-60 {
      opacity: 0.6;
}
.opacity-70 {
      opacity: 0.7;
}
.opacity-80 {
      opacity: 0.8;
}
.opacity-90 {
      opacity: 0.9;
}
.\!shadow-none {
      --tw-shadow: 0 0 #0000 !important;
      --tw-shadow-colored: 0 0 #0000 !important;
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.shadow-\[-12px_4px_30px_5px_rgba\(24\2c 77\2c 195\2c 0\.12\)\] {
      --tw-shadow: -12px 4px 30px 5px rgba(24,77,195,0.12);
      --tw-shadow-colored: -12px 4px 30px 5px var(--tw-shadow-color);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-\[-4px_-12px_30px_5px_rgba\(24\2c 77\2c 195\2c 0\.12\)\] {
      --tw-shadow: -4px -12px 30px 5px rgba(24,77,195,0.12);
      --tw-shadow-colored: -4px -12px 30px 5px var(--tw-shadow-color);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-\[0px_0px_80px_0px_rgba\(59\2c 130\2c 246\2c 0\.24\)\] {
      --tw-shadow: 0px 0px 80px 0px rgba(59,130,246,0.24);
      --tw-shadow-colored: 0px 0px 80px 0px var(--tw-shadow-color);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-cardShadow {
      --tw-shadow: 0px 0px 80px rgba(59, 130, 246, 0.24);
      --tw-shadow-colored: 0px 0px 80px var(--tw-shadow-color);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-containerShadow {
      --tw-shadow: 0px 4px 12px rgba(30, 58, 138, 0.1);
      --tw-shadow-colored: 0px 4px 12px var(--tw-shadow-color);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-dropdownShadow {
      --tw-shadow: 0px 4px 16px 0px rgba(206, 212, 240, 0.80);
      --tw-shadow-colored: 0px 4px 16px 0px var(--tw-shadow-color);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-lg {
      --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
      --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-md {
      --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
      --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-sm {
      --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
      --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-indigo-500\/50 {
      --tw-shadow-color: rgb(99 102 241 / 0.5);
      --tw-shadow: var(--tw-shadow-colored);
}
.outline-none {
      outline: 2px solid transparent;
      outline-offset: 2px;
}
.outline {
      outline-style: solid;
}
.blur {
      --tw-blur: blur(8px);
      filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.contrast-75 {
      --tw-contrast: contrast(.75);
      filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.drop-shadow {
      --tw-drop-shadow: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1)) drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));
      filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.drop-shadow-md {
      --tw-drop-shadow: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
      filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.grayscale {
      --tw-grayscale: grayscale(100%);
      filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.grayscale-\[0\.9\] {
      --tw-grayscale: grayscale(0.9);
      filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.saturate-50 {
      --tw-saturate: saturate(.5);
      filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.filter {
      filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.\!backdrop-blur-\[15px\] {
      --tw-backdrop-blur: blur(15px) !important;
      -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
              backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}
.backdrop-blur-\[10px\] {
      --tw-backdrop-blur: blur(10px);
      -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
              backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}
.backdrop-blur-\[15px\] {
      --tw-backdrop-blur: blur(15px);
      -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
              backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}
.backdrop-blur-\[2px\] {
      --tw-backdrop-blur: blur(2px);
      -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
              backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}
.backdrop-blur-sm {
      --tw-backdrop-blur: blur(4px);
      -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
              backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}
.transition {
      transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
      transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
      transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 150ms;
}
.transition-\[max-height\] {
      transition-property: max-height;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 150ms;
}
.transition-all {
      transition-property: all;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 150ms;
}
.transition-opacity {
      transition-property: opacity;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 150ms;
}
.transition-shadow {
      transition-property: box-shadow;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 150ms;
}
.transition-transform {
      transition-property: transform;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 150ms;
}
.duration-1000 {
      transition-duration: 1000ms;
}
.duration-150 {
      transition-duration: 150ms;
}
.duration-200 {
      transition-duration: 200ms;
}
.duration-300 {
      transition-duration: 300ms;
}
.duration-500 {
      transition-duration: 500ms;
}
.duration-700 {
      transition-duration: 700ms;
}
.ease-in {
      transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}
.ease-in-out {
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.ease-out {
      transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
.no-scrollbar::-webkit-scrollbar {
      display: none;
    }
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

*:not(.tox *) {
  font-family: var(--inter-font);
}

.w-inherit {
  width: inherit;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: background-color 5000000s ease-in-out 0s;
  transition: background-color 5000000s ease-in-out 0s;
}

input[type='radio'] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  outline: none;
  border: 3px solid gray;
}

input[type='radio']:before {
  content: '';
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}

input[type='radio']:checked:before {
  background: #1e40af;
}

input[type='radio']:checked {
  border-color: #1e40af;
}

.transition-width {
  transition: width 0.3s ease-in-out;
}

.doc-editor.doc-editor-modal .tox.tox-tinymce .tox-edit-area {
  height: calc(100vh - 20.45rem) !important;
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.custom-border-color {
  border-color: #cbd5e1;
}

.sidebar-bg-gradient-1 {
  background: linear-gradient(90deg, #0430af 0%, #0d2056 153.72%);
}

.aira-bg-gradient {
  background: linear-gradient(90deg, #1e3a8a 0%, #5779d8 100%) !important;
}
.dashboard-bg-gradient {
  background: linear-gradient(180deg, #ede9f4 0%, #ddeaed 100%) !important;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15) !important;
}

.sidebar-erica-bg-gradient-1 {
  border-radius: 6.25rem;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: linear-gradient(90deg, rgba(178, 185, 231, 0.45) 0%, rgba(218, 226, 252, 0.6) 100%);
  box-shadow: -6px -2px 50px 0px rgba(150, 148, 255, 0.05), -7px -4px 50px 0px rgba(187, 8, 249, 0.1);
}

.sidebar-erica-dark-bg-gradient-1 {
  border-radius: 6.25rem;
  border: 1px solid #8b97bc;
  background: linear-gradient(90deg, #183178 0%, #121e40 100%);
  box-shadow: -6px -2px 50px 0px rgba(150, 148, 255, 0.05), -7px -4px 50px 0px rgba(187, 8, 249, 0.1);
}
.sidebar-erica-dark-bg-gradient-2 {
  border-radius: 30894080rem;
  background: conic-gradient(
    from -83.04deg at 50% 50%,
    rgba(237, 14, 202, 0.8) 0%,
    rgba(255, 101, 230, 0.8) 21.35416865348816%,
    rgba(109, 106, 255, 0.8) 35.9375%,
    rgba(139, 34, 122, 0.8) 56.99999928474426%,
    rgba(82, 193, 255, 0.8) 77.99999713897705%,
    rgba(237, 14, 202, 0.8) 100%
  );
  transform: scaleX(-1);
  filter: blur(25px);
}
.sidebar-erica-bg-gradient-2 {
  border-radius: 30894080rem;

  background: conic-gradient(
    from -83.04deg at 50% 50%,
    rgba(105, 4, 255, 0.6) 0%,
    rgba(33, 75, 211, 0.6) 76.87500178813934deg,
    rgba(109, 106, 255, 0.6) 129.375deg,
    rgba(139, 34, 122, 0.6) 205.19999742507935deg,
    rgba(82, 193, 255, 0.6) 280.7999897003174deg,
    rgba(105, 4, 255, 0.6) 100%
  );
  transform: scaleX(-1);
  filter: blur(25px);
}

.sidebar {
  box-shadow: 0px 0px 16px 0px rgba(96, 165, 250, 0.16);
}
.dashboard-modal-shadow {
  box-shadow: 0px 4px 12px 0px rgba(30, 58, 138, 0.1) !important;
}
.premium-bg-gradient {
  border-radius: 0.9375rem;
  border: 1px solid #1c3479;
  background: linear-gradient(180deg, #143db5 0%, #0a142f 100%);
}
.premium-text-gradient {
  background: linear-gradient(90deg, #ecd373 4.29%, #fff3db 35.98%, #f5d738 97.5%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#headlessui-portal-root > [data-headlessui-portal]:not(:last-child) {
  visibility: hidden;
}

/* Custom styles */

#sentry-feedback {
  --background: #ffffff;
  --border: 1px solid #1e3a8a;
  --foreground: #1e3a8a;
  --submit-background: #1e3a8a;
  --submit-background-hover: #1e40af;
  --input-outline-focus: none;
  .widget__actor {
    svg {
      color: #1e3a8a;
    }
  }
}

.newTableTd {
  border: solid 1px #e5e7eb;
  border-style: solid none;
}
.newTableTd:first-child {
  border-left-style: solid;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.newTableTd:last-child {
  border-right-style: solid;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}

.newTableTh:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.newTableTh:last-child {
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}

.footer-box-shadow {
  box-shadow: -4px -2px 10px 0px rgba(166, 166, 166, 0.15);
}

.Toastify__toast-container--top-right:has(.customToast) {
  width: auto;
}

.animateAI-gradient {
  animation: animateAIBg 2s linear infinite;
  background-image: linear-gradient(90deg, #f0bbe9, #9ccaff, #f0bbe9, #9ccaff);
  background-size: 300% 100%;
}
@keyframes animateAIBg {
  0% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}
.placeholder\:font-normal::-moz-placeholder {
      font-weight: 400;
}
.placeholder\:font-normal::placeholder {
      font-weight: 400;
}
.placeholder\:text-gray-300::-moz-placeholder {
      --tw-text-opacity: 1;
      color: rgb(209 213 219 / var(--tw-text-opacity));
}
.placeholder\:text-gray-300::placeholder {
      --tw-text-opacity: 1;
      color: rgb(209 213 219 / var(--tw-text-opacity));
}
.placeholder\:text-gray-400::-moz-placeholder {
      --tw-text-opacity: 1;
      color: rgb(156 163 175 / var(--tw-text-opacity));
}
.placeholder\:text-gray-400::placeholder {
      --tw-text-opacity: 1;
      color: rgb(156 163 175 / var(--tw-text-opacity));
}
.placeholder\:text-neutral-300::-moz-placeholder {
      --tw-text-opacity: 1;
      color: rgb(212 212 212 / var(--tw-text-opacity));
}
.placeholder\:text-neutral-300::placeholder {
      --tw-text-opacity: 1;
      color: rgb(212 212 212 / var(--tw-text-opacity));
}
.placeholder\:text-neutral-500::-moz-placeholder {
      --tw-text-opacity: 1;
      color: rgb(115 115 115 / var(--tw-text-opacity));
}
.placeholder\:text-neutral-500::placeholder {
      --tw-text-opacity: 1;
      color: rgb(115 115 115 / var(--tw-text-opacity));
}
.first\:\!mt-2:first-child {
      margin-top: 0.5rem !important;
}
.last\:mt-3:last-child {
      margin-top: 0.75rem;
}
.last\:flex:last-child {
      display: flex;
}
.focus-within\:h-auto:focus-within {
      height: auto;
}
.focus-within\:\!w-full:focus-within {
      width: 100% !important;
}
.focus-within\:w-full:focus-within {
      width: 100%;
}
.focus-within\:opacity-100:focus-within {
      opacity: 1;
}
.hover\:scale-105:hover {
      --tw-scale-x: 1.05;
      --tw-scale-y: 1.05;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.hover\:scale-110:hover {
      --tw-scale-x: 1.1;
      --tw-scale-y: 1.1;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.hover\:cursor-not-allowed:hover {
      cursor: not-allowed;
}
.hover\:cursor-pointer:hover {
      cursor: pointer;
}
.hover\:rounded-full:hover {
      border-radius: 9999px;
}
.hover\:border-orange-400:hover {
      --tw-border-opacity: 1;
      border-color: rgb(251 146 60 / var(--tw-border-opacity));
}
.hover\:border-b-neutral-300:hover {
      --tw-border-opacity: 1;
      border-bottom-color: rgb(212 212 212 / var(--tw-border-opacity));
}
.hover\:\!bg-\[\#141431\]:hover {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(20 20 49 / var(--tw-bg-opacity)) !important;
}
.hover\:\!bg-blue-50:hover {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(239 246 255 / var(--tw-bg-opacity)) !important;
}
.hover\:\!bg-gray-200:hover {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(229 231 235 / var(--tw-bg-opacity)) !important;
}
.hover\:\!bg-gray-800:hover {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(31 41 55 / var(--tw-bg-opacity)) !important;
}
.hover\:\!bg-green-700:hover {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(21 128 61 / var(--tw-bg-opacity)) !important;
}
.hover\:\!bg-indigo-400\/50:hover {
      background-color: rgb(129 140 248 / 0.5) !important;
}
.hover\:\!bg-indigo-500:hover {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(99 102 241 / var(--tw-bg-opacity)) !important;
}
.hover\:\!bg-indigo-900:hover {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(49 46 129 / var(--tw-bg-opacity)) !important;
}
.hover\:\!bg-orange-100:hover {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(255 237 213 / var(--tw-bg-opacity)) !important;
}
.hover\:\!bg-red-600:hover {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(220 38 38 / var(--tw-bg-opacity)) !important;
}
.hover\:\!bg-teal-200:hover {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(153 246 228 / var(--tw-bg-opacity)) !important;
}
.hover\:\!bg-teal-50:hover {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(240 253 250 / var(--tw-bg-opacity)) !important;
}
.hover\:\!bg-violet-200:hover {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(221 214 254 / var(--tw-bg-opacity)) !important;
}
.hover\:bg-\[\#0009\]:hover {
      background-color: #0009;
}
.hover\:bg-blue-100:hover {
      --tw-bg-opacity: 1;
      background-color: rgb(219 234 254 / var(--tw-bg-opacity));
}
.hover\:bg-blue-200:hover {
      --tw-bg-opacity: 1;
      background-color: rgb(191 219 254 / var(--tw-bg-opacity));
}
.hover\:bg-blue50:hover {
      --tw-bg-opacity: 1;
      background-color: rgb(239 246 255 / var(--tw-bg-opacity));
}
.hover\:bg-blue800:hover {
      --tw-bg-opacity: 1;
      background-color: rgb(30 64 175 / var(--tw-bg-opacity));
}
.hover\:bg-gray-50:hover {
      --tw-bg-opacity: 1;
      background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}
.hover\:bg-green-500:hover {
      --tw-bg-opacity: 1;
      background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}
.hover\:bg-lime-100:hover {
      --tw-bg-opacity: 1;
      background-color: rgb(236 252 203 / var(--tw-bg-opacity));
}
.hover\:bg-neutral100:hover {
      --tw-bg-opacity: 1;
      background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}
.hover\:bg-orange-700:hover {
      --tw-bg-opacity: 1;
      background-color: rgb(194 65 12 / var(--tw-bg-opacity));
}
.hover\:bg-red-100:hover {
      --tw-bg-opacity: 1;
      background-color: rgb(254 226 226 / var(--tw-bg-opacity));
}
.hover\:bg-red-500:hover {
      --tw-bg-opacity: 1;
      background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}
.hover\:bg-slate-100:hover {
      --tw-bg-opacity: 1;
      background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}
.hover\:bg-slate-200:hover {
      --tw-bg-opacity: 1;
      background-color: rgb(226 232 240 / var(--tw-bg-opacity));
}
.hover\:bg-violet-100:hover {
      --tw-bg-opacity: 1;
      background-color: rgb(237 233 254 / var(--tw-bg-opacity));
}
.hover\:bg-white:hover {
      --tw-bg-opacity: 1;
      background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.hover\:bg-\[radial-gradient\(circle_farthest-side_at_50\%_150\%\2c _\#572ca7_10\%\2c _\#6a3fda_65\%\2c _rgba\(30\2c 58\2c 138\2c 1\)_100\%\)\]:hover {
      background-image: radial-gradient(circle farthest-side at 50% 150%, #572ca7 10%, #6a3fda 65%, rgba(30,58,138,1) 100%);
}
.hover\:bg-gradient-to-r:hover {
      background-image: linear-gradient(to right, var(--tw-gradient-stops));
}
.hover\:\!text-teal-800:hover {
      --tw-text-opacity: 1 !important;
      color: rgb(17 94 89 / var(--tw-text-opacity)) !important;
}
.hover\:\!shadow-none:hover {
      --tw-shadow: 0 0 #0000 !important;
      --tw-shadow-colored: 0 0 #0000 !important;
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.hover\:shadow-buttonShadow:hover {
      --tw-shadow: 0px 4px 24px rgba(37, 99, 235, 0.24);
      --tw-shadow-colored: 0px 4px 24px var(--tw-shadow-color);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.hover\:shadow-lg:hover {
      --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
      --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.hover\:shadow-indigo-500\/50:hover {
      --tw-shadow-color: rgb(99 102 241 / 0.5);
      --tw-shadow: var(--tw-shadow-colored);
}
.hover\:outline-none:hover {
      outline: 2px solid transparent;
      outline-offset: 2px;
}
.hover\:backdrop-contrast-50:hover {
      --tw-backdrop-contrast: contrast(.5);
      -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
              backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}
.focus\:\!w-full:focus {
      width: 100% !important;
}
.focus\:\!border-solid:focus {
      border-style: solid !important;
}
.focus\:border-blue800:focus {
      --tw-border-opacity: 1;
      border-color: rgb(30 64 175 / var(--tw-border-opacity));
}
.focus\:border-b-neutral-300:focus {
      --tw-border-opacity: 1;
      border-bottom-color: rgb(212 212 212 / var(--tw-border-opacity));
}
.focus\:\!bg-\[unset\]:focus {
      background-color: unset !important;
}
.focus\:\!bg-blue-50:focus {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(239 246 255 / var(--tw-bg-opacity)) !important;
}
.focus\:\!bg-indigo-500:focus {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(99 102 241 / var(--tw-bg-opacity)) !important;
}
.focus\:\!bg-indigo-900:focus {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(49 46 129 / var(--tw-bg-opacity)) !important;
}
.focus\:\!bg-lime-200:focus {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(217 249 157 / var(--tw-bg-opacity)) !important;
}
.focus\:\!bg-red-100:focus {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(254 226 226 / var(--tw-bg-opacity)) !important;
}
.focus\:bg-blue100:focus {
      --tw-bg-opacity: 1;
      background-color: rgb(219 234 254 / var(--tw-bg-opacity));
}
.focus\:bg-blue50:focus {
      --tw-bg-opacity: 1;
      background-color: rgb(239 246 255 / var(--tw-bg-opacity));
}
.focus\:bg-blue800:focus {
      --tw-bg-opacity: 1;
      background-color: rgb(30 64 175 / var(--tw-bg-opacity));
}
.focus\:bg-gray-50:focus {
      --tw-bg-opacity: 1;
      background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}
.focus\:bg-orange-700:focus {
      --tw-bg-opacity: 1;
      background-color: rgb(194 65 12 / var(--tw-bg-opacity));
}
.focus\:bg-\[radial-gradient\(circle_farthest-side_at_50\%_150\%\2c _\#572ca7_10\%\2c _\#6a3fda_65\%\2c _rgba\(30\2c 58\2c 138\2c 1\)_100\%\)\]:focus {
      background-image: radial-gradient(circle farthest-side at 50% 150%, #572ca7 10%, #6a3fda 65%, rgba(30,58,138,1) 100%);
}
.focus\:\!opacity-100:focus {
      opacity: 1 !important;
}
.focus\:shadow-lg:focus {
      --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
      --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.focus\:shadow-none:focus {
      --tw-shadow: 0 0 #0000;
      --tw-shadow-colored: 0 0 #0000;
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.focus\:shadow-indigo-500\/50:focus {
      --tw-shadow-color: rgb(99 102 241 / 0.5);
      --tw-shadow: var(--tw-shadow-colored);
}
.focus\:outline-none:focus {
      outline: 2px solid transparent;
      outline-offset: 2px;
}
.focus\:ring-0:focus {
      --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
      --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
      box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.active\:\!bg-green-700:active {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(21 128 61 / var(--tw-bg-opacity)) !important;
}
.active\:\!bg-red-600:active {
      --tw-bg-opacity: 1 !important;
      background-color: rgb(220 38 38 / var(--tw-bg-opacity)) !important;
}
.active\:bg-teal-50:active {
      --tw-bg-opacity: 1;
      background-color: rgb(240 253 250 / var(--tw-bg-opacity));
}
.disabled\:bg-gray-100:disabled {
      --tw-bg-opacity: 1;
      background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}
.disabled\:bg-gray-50:disabled {
      --tw-bg-opacity: 1;
      background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}
.disabled\:bg-transparent:disabled {
      background-color: transparent;
}
.disabled\:text-gray-500:disabled {
      --tw-text-opacity: 1;
      color: rgb(107 114 128 / var(--tw-text-opacity));
}
.disabled\:text-grey500:disabled {
      --tw-text-opacity: 1;
      color: rgb(107 114 128 / var(--tw-text-opacity));
}
.disabled\:hover\:cursor-not-allowed:hover:disabled {
      cursor: not-allowed;
}
.disabled\:hover\:bg-gray-200:hover:disabled {
      --tw-bg-opacity: 1;
      background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}
.group:hover .group-hover\:block {
      display: block;
}
.group\/delete:hover .group-hover\/delete\:scale-110 {
      --tw-scale-x: 1.1;
      --tw-scale-y: 1.1;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.peer:-moz-placeholder-shown ~ .peer-placeholder-shown\:top-1\/2 {
      top: 50%;
}
.peer:placeholder-shown ~ .peer-placeholder-shown\:top-1\/2 {
      top: 50%;
}
.peer:-moz-placeholder-shown ~ .peer-placeholder-shown\:-translate-y-1\/2 {
      --tw-translate-y: -50%;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.peer:placeholder-shown ~ .peer-placeholder-shown\:-translate-y-1\/2 {
      --tw-translate-y: -50%;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.peer:-moz-placeholder-shown ~ .peer-placeholder-shown\:scale-100 {
      --tw-scale-x: 1;
      --tw-scale-y: 1;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.peer:placeholder-shown ~ .peer-placeholder-shown\:scale-100 {
      --tw-scale-x: 1;
      --tw-scale-y: 1;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.peer\/cont:focus-within ~ .peer-focus-within\/cont\:flex {
      display: flex;
}
.peer\/cont:focus-within ~ .peer-focus-within\/cont\:hidden {
      display: none;
}
.peer:focus-within ~ .peer-focus-within\:h-0 {
      height: 0px;
}
.peer:focus-within ~ .peer-focus-within\:w-0 {
      width: 0px;
}
.peer:focus-within ~ .peer-focus-within\:opacity-0 {
      opacity: 0;
}
.peer:focus ~ .peer-focus\:top-2 {
      top: 0.5rem;
}
.peer:focus ~ .peer-focus\:-translate-y-8 {
      --tw-translate-y: -2rem;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.peer:focus ~ .peer-focus\:scale-75 {
      --tw-scale-x: .75;
      --tw-scale-y: .75;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.peer:focus ~ .peer-focus\:px-2 {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
}
.peer:focus ~ .peer-focus\:font-semibold {
      font-weight: 600;
}
.peer:focus-within ~ .peer-\[\:focus-within\]\:hidden {
      display: none;
}
@media (min-width: 640px) {
      .sm\:ml-\[150px\] {
            margin-left: 150px;
      }
      .sm\:w-1\/2 {
            width: 50%;
      }
      .sm\:w-1\/5 {
            width: 20%;
      }
      .sm\:w-2\/5 {
            width: 40%;
      }
      .sm\:w-\[50rem\] {
            width: 50rem;
      }
      .sm\:w-auto {
            width: auto;
      }
      .sm\:max-w-\[150px\] {
            max-width: 150px;
      }
      .sm\:grid-cols-1 {
            grid-template-columns: repeat(1, minmax(0, 1fr));
      }
      .sm\:grid-cols-2 {
            grid-template-columns: repeat(2, minmax(0, 1fr));
      }
      .sm\:grid-rows-3 {
            grid-template-rows: repeat(3, minmax(0, 1fr));
      }
      .sm\:flex-nowrap {
            flex-wrap: nowrap;
      }
      .sm\:gap-16 {
            gap: 4rem;
      }
      .sm\:whitespace-nowrap {
            white-space: nowrap;
      }
}
@media (min-width: 768px) {
      .md\:ml-\[300px\] {
            margin-left: 300px;
      }
      .md\:w-1\/2 {
            width: 50%;
      }
      .md\:w-1\/3 {
            width: 33.333333%;
      }
      .md\:max-w-\[100px\] {
            max-width: 100px;
      }
      .md\:max-w-\[17rem\] {
            max-width: 17rem;
      }
      .md\:max-w-\[200px\] {
            max-width: 200px;
      }
      .md\:max-w-\[820px\] {
            max-width: 820px;
      }
      .md\:max-w-\[8rem\] {
            max-width: 8rem;
      }
      .md\:grid-cols-2 {
            grid-template-columns: repeat(2, minmax(0, 1fr));
      }
      .md\:grid-cols-3 {
            grid-template-columns: repeat(3, minmax(0, 1fr));
      }
}
@media (min-width: 1000px) {
      .min-\[1000px\]\:w-\[10rem\] {
            width: 10rem;
      }
}
@media (min-width: 1024px) {
      .lg\:ml-\[400px\] {
            margin-left: 400px;
      }
      .lg\:ml-auto {
            margin-left: auto;
      }
      .lg\:w-\[70rem\] {
            width: 70rem;
      }
      .lg\:max-w-5xl {
            max-width: 64rem;
      }
      .lg\:max-w-\[120px\] {
            max-width: 120px;
      }
      .lg\:max-w-\[150px\] {
            max-width: 150px;
      }
      .lg\:max-w-\[180px\] {
            max-width: 180px;
      }
      .lg\:max-w-\[22rem\] {
            max-width: 22rem;
      }
      .lg\:max-w-\[250px\] {
            max-width: 250px;
      }
      .lg\:grid-cols-3 {
            grid-template-columns: repeat(3, minmax(0, 1fr));
      }
      .lg\:grid-cols-4 {
            grid-template-columns: repeat(4, minmax(0, 1fr));
      }
      .lg\:flex-row {
            flex-direction: row;
      }
}
@media (min-width: 1280px) {
      .xl\:ml-\[500px\] {
            margin-left: 500px;
      }
      .xl\:max-w-\[12rem\] {
            max-width: 12rem;
      }
      .xl\:max-w-\[150px\] {
            max-width: 150px;
      }
      .xl\:max-w-\[200px\] {
            max-width: 200px;
      }
      .xl\:max-w-\[250px\] {
            max-width: 250px;
      }
      .xl\:max-w-\[450px\] {
            max-width: 450px;
      }
      .xl\:max-w-lg {
            max-width: 32rem;
      }
      .xl\:grid-cols-4 {
            grid-template-columns: repeat(4, minmax(0, 1fr));
      }
      .xl\:grid-cols-5 {
            grid-template-columns: repeat(5, minmax(0, 1fr));
      }
}
@media (min-width: 1400px) {
      .min-\[1400px\]\:w-\[12\.5rem\] {
            width: 12.5rem;
      }
      .min-\[1400px\]\:w-\[12rem\] {
            width: 12rem;
      }
      .min-\[1400px\]\:max-w-\[20rem\] {
            max-width: 20rem;
      }
      .min-\[1400px\]\:max-w-\[22rem\] {
            max-width: 22rem;
      }
}
@media (min-width: 1536px) {
      .\32xl\:ml-\[650px\] {
            margin-left: 650px;
      }
      .\32xl\:max-w-\[200px\] {
            max-width: 200px;
      }
      .\32xl\:max-w-\[20rem\] {
            max-width: 20rem;
      }
      .\32xl\:max-w-\[250px\] {
            max-width: 250px;
      }
      .\32xl\:max-w-\[500px\] {
            max-width: 500px;
      }
      .\32xl\:max-w-\[700px\] {
            max-width: 700px;
      }
}
@media (min-width: 1600px) {
      .min-\[1600px\]\:w-\[15rem\] {
            width: 15rem;
      }
      .min-\[1600px\]\:w-\[20rem\] {
            width: 20rem;
      }
      .min-\[1600px\]\:w-\[23rem\] {
            width: 23rem;
      }
      .min-\[1600px\]\:max-w-\[30rem\] {
            max-width: 30rem;
      }
}
@media (min-width: 1700px) {
      .min-\[1700px\]\:max-w-\[26rem\] {
            max-width: 26rem;
      }
}
@media (min-width: 1800px) {
      .min-\[1800px\]\:w-\[20rem\] {
            width: 20rem;
      }
      .min-\[1800px\]\:w-\[35rem\] {
            width: 35rem;
      }
      .min-\[1800px\]\:max-w-\[40rem\] {
            max-width: 40rem;
      }
}

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

.Toastify__toast-container {
  z-index: var(--toastify-z-index);
  -webkit-transform: translate3d(0, 0, var(--toastify-z-index));
  position: fixed;
  padding: 4px;
  width: var(--toastify-toast-width);
  box-sizing: border-box;
  color: #fff;
}
.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em;
}
.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}
.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}
.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em;
}
.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
}
.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}

@media only screen and (max-width : 480px) {
  .Toastify__toast-container {
    width: 100vw;
    padding: 0;
    left: 0;
    margin: 0;
  }
  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 0;
    transform: translateX(0);
  }
  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: 0;
    transform: translateX(0);
  }
  .Toastify__toast-container--rtl {
    right: 0;
    left: initial;
  }
}
.Toastify__toast {
  position: relative;
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  max-height: var(--toastify-toast-max-height);
  overflow: hidden;
  font-family: var(--toastify-font-family);
  cursor: default;
  direction: ltr;
  /* webkit only issue #791 */
  z-index: 0;
}
.Toastify__toast--rtl {
  direction: rtl;
}
.Toastify__toast--close-on-click {
  cursor: pointer;
}
.Toastify__toast-body {
  margin: auto 0;
  flex: 1 1 auto;
  padding: 6px;
  display: flex;
  align-items: center;
}
.Toastify__toast-body > div:last-child {
  word-break: break-word;
  flex: 1;
}
.Toastify__toast-icon {
  margin-inline-end: 10px;
  width: 20px;
  flex-shrink: 0;
  display: flex;
}

.Toastify--animate {
  animation-fill-mode: both;
  animation-duration: 0.7s;
}

.Toastify--animate-icon {
  animation-fill-mode: both;
  animation-duration: 0.3s;
}

@media only screen and (max-width : 480px) {
  .Toastify__toast {
    margin-bottom: 0;
    border-radius: 0;
  }
}
.Toastify__toast-theme--dark {
  background: var(--toastify-color-dark);
  color: var(--toastify-text-color-dark);
}
.Toastify__toast-theme--light {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}
.Toastify__toast-theme--colored.Toastify__toast--default {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}
.Toastify__toast-theme--colored.Toastify__toast--info {
  color: var(--toastify-text-color-info);
  background: var(--toastify-color-info);
}
.Toastify__toast-theme--colored.Toastify__toast--success {
  color: var(--toastify-text-color-success);
  background: var(--toastify-color-success);
}
.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: var(--toastify-text-color-warning);
  background: var(--toastify-color-warning);
}
.Toastify__toast-theme--colored.Toastify__toast--error {
  color: var(--toastify-text-color-error);
  background: var(--toastify-color-error);
}

.Toastify__progress-bar-theme--light {
  background: var(--toastify-color-progress-light);
}
.Toastify__progress-bar-theme--dark {
  background: var(--toastify-color-progress-dark);
}
.Toastify__progress-bar--info {
  background: var(--toastify-color-progress-info);
}
.Toastify__progress-bar--success {
  background: var(--toastify-color-progress-success);
}
.Toastify__progress-bar--warning {
  background: var(--toastify-color-progress-warning);
}
.Toastify__progress-bar--error {
  background: var(--toastify-color-progress-error);
}
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background: var(--toastify-color-transparent);
}

.Toastify__close-button {
  color: #fff;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s ease;
  align-self: flex-start;
}
.Toastify__close-button--light {
  color: #000;
  opacity: 0.3;
}
.Toastify__close-button > svg {
  fill: currentColor;
  height: 16px;
  width: 14px;
}
.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}
.Toastify__progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  z-index: var(--toastify-z-index);
  opacity: 0.7;
  transform-origin: left;
}
.Toastify__progress-bar--animated {
  animation: Toastify__trackProgress linear 1 forwards;
}
.Toastify__progress-bar--controlled {
  transition: transform 0.2s;
}
.Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: right;
}

.Toastify__spinner {
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border: 2px solid;
  border-radius: 100%;
  border-color: var(--toastify-spinner-color-empty-area);
  border-right-color: var(--toastify-spinner-color);
  animation: Toastify__spin 0.65s linear infinite;
}

@keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    transform: translate3d(10px, 0, 0);
  }
  90% {
    transform: translate3d(-5px, 0, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}
@keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }
  75% {
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    transform: translate3d(5px, 0, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}
@keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  75% {
    transform: translate3d(0, 10px, 0);
  }
  90% {
    transform: translate3d(0, -5px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}
@keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }
  75% {
    transform: translate3d(0, -10px, 0);
  }
  90% {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}
.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft;
}
.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight;
}
.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}
.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft;
}
.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight;
}
.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}
.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    transform: perspective(400px);
  }
}
@keyframes Toastify__flipOut {
  from {
    transform: perspective(400px);
  }
  30% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
  from {
    transform: translate3d(110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInLeft {
  from {
    transform: translate3d(-110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInUp {
  from {
    transform: translate3d(0, 110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInDown {
  from {
    transform: translate3d(0, -110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}
@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}
@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}
@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}
.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft;
}
.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight;
}
.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}
.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft;
}
.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight;
}
.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
}
.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
}

@keyframes Toastify__spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*# sourceMappingURL=ReactToastify.css.map */
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_a184c8';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_a184c8';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_a184c8';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_a184c8';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_a184c8';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_a184c8';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_a184c8';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_a184c8';src: local("Arial");ascent-override: 90.00%;descent-override: 22.43%;line-gap-override: 0.00%;size-adjust: 107.64%
}.__className_a184c8 {font-family: '__Inter_a184c8', '__Inter_Fallback_a184c8';font-style: normal
}.__variable_a184c8 {--inter-font: '__Inter_a184c8', '__Inter_Fallback_a184c8'
}

